//React
import React from "react";
import PropTypes from "prop-types";

// Components
import SettingsPanel from "../SettingsPanel";
import {minHourlyRateEur, maxHourlyRateEur} from "../../constants/settings";
import {fetchEurExchangeRates} from "../../services/currencies";

// Context
import {FirebaseContext} from "../Firebase";
import {AuthUserContext} from "../Session";

// Utils
import {
  formatCentsToDisplayPrice,
  formatPriceToCents,
  getCurrencyFormat,
  getCurrencySymbol
} from "../../utils/formatting";

// Material UI
import {InputAdornment, TextField, FormHelperText} from "@material-ui/core";

export default function HourlyRatePanel(props) {
  const authUser = React.useContext(AuthUserContext);

  const hourlyRateDisplay = authUser.hourlyRate ? formatCentsToDisplayPrice(authUser.hourlyRate) : null;
  const [hourlyRate, setHourlyRate] = React.useState(hourlyRateDisplay);
  const [lastSavedHourlyRate, setLastSavedHourlyRate] = React.useState(hourlyRateDisplay);
  const [currencyRates, setCurrencyRates] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [errorMessage, serErrorMessage] = React.useState(null);

  const firebase = React.useContext(FirebaseContext);
  const {handlePanelChange, isExpanded, draftMode} = props;

  React.useEffect(() => {
    setIsFetching(true);
    fetchEurExchangeRates(authUser.currency)
      .then((currencyRates) => {
        setCurrencyRates(currencyRates);
        setIsFetching(false);
      })
      .catch((error) => {
        serErrorMessage(`We encountered an error: "${error}". Contact info@maxer.io if the problem persists.`);
        setIsFetching(false)
      })
  }, [authUser.currency]);

  const handleChange = (prop) => (event) => setHourlyRate(Math.round(event.target.value));

  const handleSave = () => {
    // Set to min or max if not within range
    const minHourlyRateInCurrency = Math.ceil(
      minHourlyRateEur / currencyRates["EUR"]
    );
    const maxHourlyRateInCurrency = Math.floor(
      maxHourlyRateEur / currencyRates["EUR"]
    );

    //Math.ceil(minHourlyRateEur / currencyRates["EUR"])
    let adjustedRate = hourlyRate;
    if (hourlyRate < minHourlyRateInCurrency) {
      adjustedRate = minHourlyRateInCurrency;
    } else if (hourlyRate > maxHourlyRateInCurrency) {
      adjustedRate = maxHourlyRateInCurrency;
    }
    setHourlyRate(adjustedRate);
    setIsSaving(true);
    firebase
      .user(authUser.uid)
      .update({
        hourlyRate: formatPriceToCents(adjustedRate),
      })
      .then(() => {
        if (!draftMode) {
          setLastSavedHourlyRate(adjustedRate);
          setIsSaving(false);
          handlePanelChange && handlePanelChange();
        }
      });
  };

  const handleCancel = () => {
    setHourlyRate(lastSavedHourlyRate);
    handlePanelChange && handlePanelChange();
  };

  const minRateLocalCurrency = currencyRates
    ? Math.ceil(minHourlyRateEur * currencyRates[authUser.currency])
    : null;
  const maxRateLocalCurrency = currencyRates
    ? Math.floor(maxHourlyRateEur * currencyRates[authUser.currency])
    : null;

  return (
    <SettingsPanel
      id="rate-panel"
      expanded={isExpanded}
      handlePanelChange={handlePanelChange}
      label="Rate"
      expandedText="Set your desired hourly rate"
      collapsedText={`${getCurrencyFormat(hourlyRate || 0, authUser.currency)} per hour`}
      onCancel={handleCancel}
      onSave={handleSave}
      isLoading={isFetching}
      isSaving={isSaving}
      unsavedChanges={
        // Should be different from saved value and within allowed range in order to be saved
        Boolean(
          hourlyRate !== lastSavedHourlyRate &&
          hourlyRate >= minRateLocalCurrency &&
          hourlyRate <= maxRateLocalCurrency
        )
      }
      draftMode={draftMode}
      error={Boolean(errorMessage)}
      errorMessage={errorMessage}
    >
      <div>
        <TextField
          id="rate-selector"
          variant="outlined"
          label="Hourly rate"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {getCurrencySymbol(authUser.currency)}
              </InputAdornment>
            ),
          }}
          value={hourlyRate || ""}
          onChange={handleChange("hourlyRate")}
        />
        <FormHelperText>
          {`Enter a value between ${getCurrencyFormat(
            minRateLocalCurrency,
            authUser.currency
          )} and ${getCurrencyFormat(
            maxRateLocalCurrency,
            authUser.currency
          )}. Keep in mind that the Maxer service and transaction fee will be deducted from your hourly rate.`}
        </FormHelperText>
      </div>
    </SettingsPanel>
  );
}

HourlyRatePanel.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  handlePanelChange: PropTypes.func,
  draftMode: PropTypes.bool,
};
