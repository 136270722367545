import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    background: theme.palette.background.light,
    // minWidth: '100%',
    // minHeight: '100%',
    width: "100%",
    minWidth: "100%",
    height: 100,
    borderRadius: "0.3rem",
    border: "1px solid " + theme.palette.background.main,
  },
  input: {
    display: "none",
  },
  button: {
    height: "100%",
    width: "100%",
    minWidth: "100%",
    minHeight: "100%",
    textAlign: "center",
  },
  innerText: {
    marginTop: 20,
    marginBottom: 20,
    height: 60,
  },
  addItemText: {
    marginTop: 5,
    display: "block",
  },
}));

export default function UploaderListItem(props) {
  const {onFieldChange} = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={onFieldChange}
      />
      <label htmlFor="contained-button-file">
        <div
          //variant="contained"
          className={classes.button}
        >
          <div className={classes.innerText}>
            <Fab
              aria-label="Add"
              size="small"
              color="primary"
              component="span"
              className={classes.addItemIcon}
            >
              <Add/>
            </Fab>
            <Typography variant="caption" className={classes.addItemText}>
              Select an image
            </Typography>
          </div>
        </div>
      </label>
    </div>
  );
}

UploaderListItem.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
};
