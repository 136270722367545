import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";
import {getCurrencySymbol} from "../../../utils/formatting";
import NumberFormat from "react-number-format";

const borderColor = "#EBEBEB";
const styles = StyleSheet.create({
  root: {
    marginTop: 5,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 3,
  },
  seperatorRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  description: {
    width: "80%",
    textAlign: "right",
    fontFamily: "Lato",
    fontSize: 10,
    paddingRight: 10,
  },
  total: {
    width: "20%",
    textAlign: "right",
    paddingRight: 10,
    fontFamily: "Lato",
    fontSize: 10,
  },
  seperatorEmpty: {
    width: "60%",
  },
  seperatorLine: {
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    width: "40%",
  },
});

export default function InvoiceBalance(props) {
  const {
    subTotal,
    salesTaxAmount,
    taxRateText,
    currency,
    taxReverseCharged,
  } = props;

  return (
    <View style={styles.root}>
      <View style={styles.row}>
        <Text style={styles.description}>Subtotal</Text>
        <Text style={styles.total}>
          <NumberFormat
            decimalScale={String(subTotal).includes(".") ? 2 : 0}
            fixedDecimalScale={String(subTotal).includes(".") ? 2 : 0}
            value={subTotal}
            displayType="text"
            thousandSeparator={true}
            prefix={currency ? getCurrencySymbol(currency, true) : ""}
          />
        </Text>
      </View>
      {taxReverseCharged ? (
        <View style={styles.row}>
          <Text style={styles.reverseChargeText}>
            Tax to be paid on reverse charge basis
          </Text>
        </View>
      ) : (
        <React.Fragment>
          <View style={styles.row}>
            <Text style={styles.description}>Tax rate</Text>
            <Text style={styles.total}>{taxRateText}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.description}>Tax amount</Text>
            <Text style={styles.total}>
              <NumberFormat
                decimalScale={String(salesTaxAmount).includes(".") ? 2 : 0}
                fixedDecimalScale={String(salesTaxAmount).includes(".") ? 2 : 0}
                value={salesTaxAmount}
                displayType="text"
                thousandSeparator={true}
                prefix={currency ? getCurrencySymbol(currency, true) : ""}
              />
            </Text>
          </View>
        </React.Fragment>
      )}
      <View style={styles.seperatorRow}>
        <View style={styles.seperatorEmpty}/>
        <View style={styles.seperatorLine}/>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Total amount</Text>
        <Text style={styles.total}>
          <NumberFormat
            decimalScale={
              String(subTotal + salesTaxAmount).includes(".") ? 2 : 0
            }
            fixedDecimalScale={
              String(subTotal + salesTaxAmount).includes(".") ? 2 : 0
            }
            value={subTotal + salesTaxAmount}
            displayType="text"
            thousandSeparator={true}
            prefix={currency ? getCurrencySymbol(currency, true) : ""}
          />
        </Text>
      </View>
    </View>
  );
}
