// React
import React from "react";
// import PropTypes from "prop-types";

// Material UI
import {Add} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {BusinessContext} from "../../Session";
import {useStripe} from "@stripe/react-stripe-js";
import {createCheckoutSession} from "../../../services/payments/sessions";
import {createCustomer} from "../../../services/payments/customers";
import {FirebaseContext} from "../../Firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(1)
  },
}));

export default function PaymentMethodToolbar(props) {
  const classes = useStyles(props);

  const {selectedBusiness} = React.useContext(BusinessContext);
  const firebase = React.useContext(FirebaseContext);
  const stripe = useStripe();

  const handleAddPm = () =>
    selectedBusiness.stripeCustomerId
      ? handleCreateCheckoutSession(selectedBusiness)
      : createCustomer()
      .then(customer => {
        firebase.business(selectedBusiness.uid)
          .update({
            lastUpdatedAt: firebase.createTimestamp(),
            stripeCustomerId: customer.id,
          });
        return handleCreateCheckoutSession({
          ...selectedBusiness,
          stripeCustomerId: customer.id
        })
      });

  const handleCreateCheckoutSession = business => {
    console.log("creating session", business);
    createCheckoutSession(business)
      .then(({data}) => {
        console.log("data", data);
        return stripe.redirectToCheckout({
          sessionId: data.id,
        })
      });
  };


  return (
    <div className={classes.root}>
      <Button color="primary" startIcon={<Add/>} onClick={handleAddPm}>
        Add method
      </Button>
    </div>
  );
}

PaymentMethodToolbar.propTypes = {
  // paymentMethods: PropTypes.array.isRequired,
  // proposal: PropTypes.object.isRequired,
};

