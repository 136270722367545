import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";
import {getCurrencyFormat} from "../../../../utils/formatting";

const borderColor = "#EBEBEB";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    marginTop: 2,
    marginBottom: 2,
    // height: 24,
  },
  lastRow: {
    flexDirection: "row",
    alignItems: "center",
    // height: 24,
  },
  firstCell: {
    width: "80%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: "10px 10px 5px 10px",
    fontSize: 10,
    fontFamily: "Lato",
  },
  lastCell: {
    width: "20%",
    textAlign: "right",
    padding: "10px 10px 5px 10px",
    fontSize: 10,
    fontFamily: "Lato",
  },
});

export default function InvoiceTableRow(props) {
  const {row, isLastRow} = props;
  const {id, description, amount, currency} = row;
  return (
    <View style={isLastRow ? styles.lastRow : styles.row} key={id}>
      <Text style={styles.firstCell}>{description}</Text>
      {/*<Text style={styles.middleCell}>{hours}</Text>*/}
      {/*<Text style={styles.middleCell}>*/}
      {/*  <NumberFormat*/}
      {/*    decimalScale={0}*/}
      {/*    value={hourlyRate}*/}
      {/*    displayType='text'*/}
      {/*    thousandSeparator={true}*/}
      {/*    prefix={currency ? getCurrencySymbol(currency, true) : ''}*/}
      {/*  />*/}
      {/*</Text>*/}
      <Text style={styles.lastCell}>
        {getCurrencyFormat(amount, currency)}
        {/*<NumberFormat*/}
        {/*  decimalScale={0}*/}
        {/*  value={amount}*/}
        {/*  displayType="text"*/}
        {/*  thousandSeparator={true}*/}
        {/*  prefix={currency ? getCurrencySymbol(currency, true) : ""}*/}
        {/*/>*/}
      </Text>
    </View>
  );
}
