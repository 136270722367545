import React from "react";

import {Text, View, StyleSheet} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#EBEBEB",
    borderBottomStyle: "solid",
    alignItems: "stretch",
    paddingBottom: 10,
  },
  detailColumn: {
    flexDirection: "column",
    flexGrow: 2,
    textTransform: "uppercase",
  },
  linkColumn: {
    flexDirection: "column",
    flexGrow: 9,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  title: {
    fontSize: 16,
    fontFamily: "Lato",
  },
  headerRightElement: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  label: {
    fontFamily: "Lato",
    fontSize: 10,
    color: "#666666",
  },
  valueText: {
    fontFamily: "Lato",
    fontSize: 10,
  },
});

export default function InvoiceHeader(props) {
  const {title, invoiceNr, invoiceDate} = props;
  return (
    <View style={styles.container}>
      <View style={styles.detailColumn}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.linkColumn}>
        <View style={styles.headerRightElement}>
          <Text style={styles.label}>Invoice #: </Text>
          <Text style={styles.valueText}>{invoiceNr}</Text>
        </View>
        <View style={styles.headerRightElement}>
          <Text style={styles.label}>Date issued: </Text>
          <Text style={styles.valueText}>
            {invoiceDate}
          </Text>
        </View>
        {/*<Link style={styles.link}>{`Invoice #${invoiceNr}`}</Link>*/}
        {/*<Text style={styles.link}>{`Issued on ${invoiceDate.toLocaleDateString()}`}</Text>*/}
      </View>
    </View>
  );
}
