// React
import React from "react";
import {Match, Redirect, useLocation} from "@reach/router";
import PropTypes from "prop-types";

// Components
import PageNotFound from "../PageNotFound";
import LoadingIndicator from "../LoadingIndicator";
import {AuthUserContext, BusinessContext} from "../Session"; //withTracker

// Constants
import * as routes from "../../constants/routes";
import { underConstruction } from "../../constants/settings";

// Other libs
import {Box} from "@material-ui/core";
import {Warning} from "@material-ui/icons";
import queryString from "query-string";
import BodyContainer from "../BodyContainer";
import NavBreadCrumbs from "../NavBreadCrumbs";
import Divider from "@material-ui/core/Divider";
import {forceTrailingSlash} from "../../utils/formatting";

export default function AppRoute(props) {
  const {component: Component, businessRequired, rolesAllowed, navCrumbs, path} = props;
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const authUser = React.useContext(AuthUserContext);
  const {selectedBusiness, isFetching} = React.useContext(BusinessContext);
  // For pathname comparisons, add a trailing slash to location pathname to make it same to routes
  const trailingSlashPath = forceTrailingSlash(location.pathname);

  if (underConstruction) {
    return (
      <PageNotFound
        message="Maxer.io is currently undergoing scheduled maintenance, and will not be available for the next few hours."
        icon={<Warning style={{ marginTop: 32, fontSize: 100 }} />}
      />
    )
  }

  if (authUser.isAuthenticating || (authUser.hasAuthenticated && !authUser.profileHasLoaded)) {
    return <LoadingIndicator message="Signing in..."/>;
  }

  // If not authenticated then redirect to login page with redirect param to deep link
  if (!authUser.hasAuthenticated) {
    const redirUrl = trailingSlashPath + location.search;
    const redirUrlDecoded = encodeURIComponent(redirUrl);
    return <Redirect to={`${routes.login}?redirect=${redirUrlDecoded}`} noThrow/> 
  }

  // If authenticated but not allowed to see page then redirect to app
  if (!rolesAllowed.includes(authUser.role)) {
    return <Redirect to={routes.app} noThrow/>
  }

  // Accepted freelancers are required to create a Stripe connect account for payouts
  if (authUser.role === "freelancer") {    
    if (authUser.status === "profileIncomplete" && trailingSlashPath !== routes.freelancerProfileSetup) {
      return <Redirect to={routes.freelancerProfileSetup} noThrow/>;
    } else if (authUser.status === "profileCompleted" && trailingSlashPath === routes.freelancerProfileSetup) {
      return <Redirect to={routes.app} noThrow/>;
    }
  }

  if (businessRequired) {
    // A business is required in all non business page manager urls
    if (!parsedQueries.business) {
      return <Redirect to={routes.app} noThrow/>;
    }

    // If is fetching business, then show loading screen
    if (isFetching) {
      return <LoadingIndicator message="Loading business..."/>;
    }

    // If fetching has finished but no business was found, or it was removed, then show not found
    if (!selectedBusiness || selectedBusiness.status === "removed") {
      return <PageNotFound/>;
    }

    // If there is a business but it's in draft status then let user complete it first
    if (selectedBusiness.status === "draft") {
      return <Redirect to={routes.clientCreateBusiness} noThrow/>;
    }
  }

  return (
    <Match path={path}>
      {({match}) => {

        const crumbs = navCrumbs ? (
          navCrumbs
            .map(({getLabel, getDestination}) => ({
              label: getLabel(match),
              destination: getDestination ? getDestination(match) : null,
            }))
        ) : [];

        return (
          <Box>
            {crumbs.length > 0 && (
              <BodyContainer>
                <NavBreadCrumbs
                  crumbs={crumbs}
                  bottomGutterSpacing={2}
                  topGutterSpacing={2}
                />
                <Divider/>
              </BodyContainer>
            )}
            <main>
              <Component {...props} match={match}/>
            </main>
          </Box>
        )
      }}
    </Match>
  );
}

AppRoute.propTypes = {
  rolesAllowed: PropTypes.array.isRequired,
};