// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles(() => ({
  paper: {
    margin: 10,
  },
  img: {
    display: "block",
    margin: "auto",
    width: "auto",
  },
  closeDialogBtn: {
    zIndex: 1,
    position: "fixed",
    top: 16,
    left: 24,
  },
}));

export default function PortfolioImageDialog(props) {
  const {isOpen, imageToEnlarge, closeDialog} = props;
  const classes = useStyles();

  return (
    <div>
      {isOpen && (
        <Dialog
          open={true}
          onClose={closeDialog}
          classes={{
            paper: classes.paper,
          }}
          maxWidth="lg"
        >
          <div>
            <img className={classes.img} alt="" src={imageToEnlarge.assetUrl}/>
          </div>
          <Fab
            size="medium"
            aria-label="Back"
            className={classes.closeDialogBtn}
            onClick={closeDialog}
          >
            <ClearIcon/>
          </Fab>
        </Dialog>
      )}
    </div>
  );
}

PortfolioImageDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  imageToEnlarge: PropTypes.object,
  closeDialog: PropTypes.func.isRequired,
};
