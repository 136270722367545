// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    marginBottom: 120,
  },
  cancelButton: {
    marginLeft: 15,
  },
}));

export default function ActionButtons(props) {
  const {onClose, onPublish} = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={onPublish}
      >
        Publish item
      </Button>
      <Button
        size="large"
        variant="outlined"
        color="primary"
        className={classes.cancelButton}
        onClick={onClose}
      >
        Cancel
      </Button>
    </div>
  );
}

ActionButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
};
