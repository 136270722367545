//React
import React from "react";
import PropTypes from "prop-types";

// Components
import SettingsPanel from "../SettingsPanel";
import SelectorTextfield from "../SelectorTextfield";
// Utils
import {hasLengthBetween} from "../../utils/validators";
// Other libs
import * as yup from "yup";

// function isValidBusiness(val) {
//   // Documentation for business regex: https://regexr.com/3au3g
//   const businessRegex = new RegExp(
//     /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
//   );
//   return businessRegex.test(val);
// }

const minLength = 2;
const maxLength = 40;
const businessFieldsSchema = yup.object({
  name: yup
    .string()
    .test("len", `Must be between ${minLength} and ${maxLength} characters`, (val) =>
      hasLengthBetween(val, minLength, maxLength)
    )
    // .test("business", "Must be a valid business name (e.g. mywebsite.com)", (val) =>
    //   isValidBusiness(val)
    // )
    .required("Enter your business name"),
});

export default function BusinessNamePanel(props) {
  const {firebaseRef, value, handlePanelChange, isExpanded, draftMode} = props;
  const [name, setName] = React.useState(value);
  const [lastSavedName, setLastSavedName] = React.useState(value);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isSaving, setIsSaving] = React.useState(false);

  const handleChange = (prop, value) => setName(value);

  const handleCancel = () => {
    setName(lastSavedName);
    handlePanelChange && handlePanelChange();
  };

  const handleSave = () => {
    validateFields()
      .then((result) => {
        if (result.isValid) {
          setIsSaving(true);
          firebaseRef
            .update({name})
            .then(() => {
              if (!draftMode) {
                setIsSaving(false);
                setLastSavedName(name);
                handlePanelChange && handlePanelChange();
              }
            });
        } else {
          setErrorMessage(result.errorMessage);
        }
      });
  };

  const validateFields = () => {
    return new Promise((resolve, reject) => {
      // Reset errorMessages state
      setErrorMessage("");
      businessFieldsSchema
        .validate({name}, {abortEarly: false})
        .then(() => resolve({isValid: true}))
        .catch((errors) => {
          const errorMessage =
            errors.inner && errors.inner[0] && errors.inner[0].message;
          resolve({isValid: false, errorMessage});
        });
    });
  };

  return (
    <SettingsPanel
      id="name-panel"
      expanded={isExpanded}
      handlePanelChange={handlePanelChange}
      label="Name"
      expandedText="Enter the name of the business you want to promote"
      collapsedText={name}
      onCancel={handleCancel}
      onSave={handleSave}
      isSaving={isSaving}
      unsavedChanges={name !== lastSavedName}
      error={Boolean(errorMessage)}
      errorMessage={errorMessage}
      draftMode={draftMode}
    >
      <SelectorTextfield
        id="name"
        label="Business name"
        value={name}
        handleChange={handleChange}
        multiline={false}
        rowsMax={1}
        nrOfRows={1}
        characterLimit={maxLength}
      />
    </SettingsPanel>
  );
}

BusinessNamePanel.propTypes = {
  firebaseRef: PropTypes.any,
  value: PropTypes.string,
  handlePanelChange: PropTypes.func,
  isExpanded: PropTypes.bool.isRequired,
  draftMode: PropTypes.bool,
};
