import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// Components
import SettingsWrapper from "./SettingsWrapper";
import ListItem from "./ListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "inherit",
  },
  arrowContainer: {
    zIndex: 1,
    position: "relative",
    width: "100%",
  },
  gridContainer: {
    width: "100%",
    margin: "auto",
  },
}));

export default function View(props) {
  const {
    itemsToShow,
    spacing,
    height,
    showLeftArrow,
    showRightArrow,
    handleLeftArrowClick,
    handleRightArrowClick,
    showListItemWithSettingsIcon,
    handleItemClick,
    changeOrder,
    deleteItem,
  } = props;
  const classes = useStyles();

  const arrowButtonRadius = 40;
  const arrowContainerTopMargin =
    height / 2 + spacing / 2 - arrowButtonRadius / 2;
  const arrowMargin = arrowButtonRadius / 2 - spacing / 2;

  return (
    <div
      className={classes.root}
      style={{
        marginLeft: -(spacing / 2),
        marginRight: -(spacing / 2),
      }}
    >
      <Grid className={classes.gridContainer} spacing={spacing} container>
        {(showLeftArrow || showRightArrow) && (
          <div
            className={classes.arrowContainer}
            style={{
              top: arrowContainerTopMargin,
            }}
          >
            {showLeftArrow && (
              <Fab
                size="small"
                style={{
                  position: "absolute",
                  left: -arrowMargin,
                }}
                onClick={handleLeftArrowClick}
              >
                <KeyboardArrowLeft/>
              </Fab>
            )}
            {showRightArrow && (
              <Fab
                size="small"
                style={{
                  position: "absolute",
                  right: -arrowMargin,
                }}
                onClick={handleRightArrowClick}
              >
                <KeyboardArrowRight/>
              </Fab>
            )}
          </div>
        )}
        {itemsToShow.map((item, index) => {
          // Compute which directions the item can move
          const canMoveBackward = index > 0 || showLeftArrow;
          const canMoveForward =
            index + 1 < itemsToShow.length || showRightArrow;
          return (
            <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={index}>
              {showListItemWithSettingsIcon ? (
                <SettingsWrapper
                  item={item}
                  canMoveBackward={canMoveBackward}
                  canMoveForward={canMoveForward}
                  changeOrder={changeOrder}
                  deleteItem={deleteItem}
                >
                  <ListItem
                    item={item}
                    height={height}
                    handleClick={() => handleItemClick(item)}
                  />
                </SettingsWrapper>
              ) : (
                <ListItem
                  item={item}
                  height={height}
                  handleClick={() => handleItemClick(item)}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

View.propTypes = {
  itemsToShow: PropTypes.array.isRequired,
  showLeftArrow: PropTypes.bool.isRequired,
  showRightArrow: PropTypes.bool.isRequired,
  handleLeftArrowClick: PropTypes.func.isRequired,
  handleRightArrowClick: PropTypes.func.isRequired,
  showListItemWithSettingsIcon: PropTypes.bool.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  spacing: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  changeOrder: PropTypes.func,
  deleteItem: PropTypes.func,
};
