//React
import React from "react";
import PropTypes from "prop-types";

// Components
import SettingsPanel from "../SettingsPanel";
import SelectorTextfield from "../SelectorTextfield";

export default function SettingsPanelTextfield(props) {
  const {
    characterLimit,
    firebaseRef,
    lastSavedValue,
    firebaseFieldKey,
    handlePanelChange,
    handleUnsavedChanges,
    id,
    isExpanded,
    label,
    formHelperText,
    collapsedTextMaxLength,
    expandedText,
    nrOfRows,
    rowsMax,
    multiline,
    error,
    errorMessage,
    draftMode
  } = props;
  const [textValue, setTextValue] = React.useState(lastSavedValue || "");
  const [isSaving, setIsSaving] = React.useState(false);

  React.useEffect(() => {
    setTextValue(lastSavedValue || "");
  }, [lastSavedValue]);

  const handleChange = (prop, value) => {
    const textValue =
      characterLimit && value ? value.substring(0, characterLimit) : value;
    setTextValue(textValue);
    if (handleUnsavedChanges !== undefined) {
      handleUnsavedChanges(textValue !== lastSavedValue);
    }
  };

  const handleSave = () => {
    if (!draftMode) {
      setIsSaving(true);
    }
    firebaseRef
      .update({[firebaseFieldKey]: textValue})
      .then(() => {
        if (!draftMode) {
          setIsSaving(false);
        }
        handlePanelChange && handlePanelChange();
      });
  };

  const handleCancel = () => {
    setTextValue(lastSavedValue);
    handlePanelChange();
  };

  let collapsedText = textValue;
  if (collapsedTextMaxLength && textValue.length > collapsedTextMaxLength) {
    collapsedText = textValue.substring(0, collapsedTextMaxLength) + "...";
  }

  return (
    <SettingsPanel
      id={id || null}
      expanded={isExpanded}
      handlePanelChange={handlePanelChange}
      label={label}
      expandedText={expandedText}
      collapsedText={collapsedText}
      onCancel={handleCancel}
      onSave={handleSave}
      isSaving={isSaving}
      unsavedChanges={textValue !== lastSavedValue}
      error={error}
      errorMessage={errorMessage}
      draftMode={draftMode}
    >
      <SelectorTextfield
        id={`${firebaseFieldKey}-field`}
        label={label}
        value={textValue}
        formHelperText={formHelperText || null}
        handleChange={handleChange}
        multiline={multiline}
        rowsMax={rowsMax}
        nrOfRows={nrOfRows}
        characterLimit={characterLimit}
      />
    </SettingsPanel>
  );
}

SettingsPanelTextfield.propTypes = {
  id: PropTypes.string,
  firebaseRef: PropTypes.any.isRequired,
  firebaseFieldKey: PropTypes.string.isRequired,
  characterLimit: PropTypes.number,
  label: PropTypes.string.isRequired,
  collapsedTextMaxLength: PropTypes.number,
  expandedText: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  handlePanelChange: PropTypes.func,
  rowsMax: PropTypes.number.isRequired,
  nrOfRows: PropTypes.number.isRequired,
  error: PropTypes.bool,
};
