// React
import React from "react";
import PropTypes from "prop-types";
import {useLocation} from "@reach/router";
import {navigate} from "gatsby";

// Context
import {BusinessContext} from "../../components/Session";
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";

// Components
import BillsTable from "../../components/BillsTable";
import TableToolbar from "./TableToolbar";
import BodyContainer from "../../components/BodyContainer";
import LoadingIndicator from "../../components/LoadingIndicator";
import EmptyStateView from "../../components/EmptyStateView";

// Services
import queryString from "query-string";
import * as routes from "../../constants/routes";
import {Receipt} from "@material-ui/icons";

const statusOptions = [
  {
    value: "all",
    text: "All",
  },
  {
    value: "draft",
    text: "Draft",
  },
  {
    value: "paid",
    text: "Paid",
  },
  {
    value: "paymentRequested",
    text: "Payment requested",
  },
  {
    value: "paymentFailed",
    text: "Payment failed",
  }
];

function getEmptyStateText(filters) {
  if (filters.statusFilter !== "all") {
    const statusFilter = statusOptions
      .filter(({ value }) => value === filters.statusFilter)[0]
    return `No bills found with status "${statusFilter.text}"`
  }
  return "No bills added by team hires so far"
}

export default function ClientBillsPage(props) {
  const firebase = React.useContext(FirebaseContext);
  const {selectedBusiness} = React.useContext(BusinessContext);
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const [filters, setFilters] = React.useState({
    queryFilter: "",
    statusFilter: "all",
  });

  const billsRef = filters.statusFilter === "all" ? (
    firebase.bills()
      .where("businessId", "==", selectedBusiness.uid)
      .limit(10)
  ) : (
    firebase.bills()
      .where("businessId", "==", selectedBusiness.uid)
      .where("status", "==", filters.statusFilter)
      .limit(10)
  );
  const [bills, loadingBills] = useFetchFirestore(
    billsRef, {type: "collection"}
  );

  const billOwnerIds = bills.map(({ownerId}) => ownerId);
  const billOwnersRef = billOwnerIds.length > 0 ? firebase.users().where("uid", "in", billOwnerIds) : null;
  const [profiles, loadingProfiles] = useFetchFirestore(
    billOwnersRef,
    {type: "collection", stopLoading: !loadingBills && billOwnerIds.length === 0}
  );

  const billTeamIds = bills.map(({teamId}) => teamId);
  const billTeamsRef = billTeamIds.length > 0 ? firebase.teams().where(`uid`, "in", billTeamIds) : null;
  const [teams, loadingTeams] = useFetchFirestore(
    billTeamsRef,
    {type: "collection", stopLoading: !loadingBills && billTeamIds.length === 0}
  );

  const handleNavigateBill = billId =>
    navigate(
      queryString.stringifyUrl({
        url: routes.clientBill.replace(":billId", billId),
        query: parsedQueries,
      })
    );

  const handleSearch = (prop) => (event) => {
    const query = event.target.value;
    setFilters((f) => ({...f, queryFilter: query}));
  };

  const handleSelectFilter = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  return (
    <BodyContainer topGutterSpacing={4} bottomGutter maxWidth={1200}>
      <TableToolbar
        statusOptions={statusOptions}
        handleSearch={handleSearch}
        filters={filters}
        handleSelectFilter={handleSelectFilter}
      />
      {(loadingBills || loadingProfiles || loadingTeams) ? (
        <LoadingIndicator
          message="Loading bills..."
          topSpacing={3}
          bottomSpacing={3}
          inheritHeight
        />
      ) : bills.length > 0 ? (
        <BillsTable
          profiles={profiles}
          bills={bills}
          teamProfiles={teams}
          freelancerProfiles={profiles}
          handleRowClick={handleNavigateBill}
        />
      ) : (
        <EmptyStateView
          text={getEmptyStateText(filters)}
          icon={<Receipt/>}
          topSpacing={10}
        />
      )}
    </BodyContainer>
  );
}

ClientBillsPage.propTypes = {
  collaborators: PropTypes.array.isRequired,
  profiles: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
};
