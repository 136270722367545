// These utility function makes it easy to quickly sort any array of objects by an attribute
// Inspiration from Material UI table component: https://material-ui.com/components/tables/#EnhancedTable.js

export function stableSort(array, cmp) {
  const stabilizedThis = array
    .map((el, index) => [el, index]);
  stabilizedThis
    .sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return stabilizedThis.map((el) => el[0]);
}

export function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
