// React
import React from "react";
import PropTypes from "prop-types";
import {useLocation} from "@reach/router";

// Components
import CardActionsContainer from "./CardActionsContainer";
import StatusLabel from "../../../components/StatusLabel";
import SimpleDialog from "../../../components/SimpleDialog";
import Link from "../../../components/Link";

// Material UI
import {
  Avatar,
  Typography,
  CardHeader,
  CardContent,
  Divider,
  Card,
} from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';

// Utils/constants
import {convertUnixTsToDate} from "../../../utils/time";
import * as routes from "../../../constants/routes";
import queryString from "query-string";
import {retrieveCustomer} from "../../../services/payments/customers";
import {createPaymentIntent, getEstTransactionFee} from "../../../services/payments/intents";
import {BusinessContext} from "../../../components/Session";
import {useStripe} from "@stripe/react-stripe-js";
import {FirebaseContext} from "../../../components/Firebase";
import {trackEvent} from "../../../services/analytics";
import {formatCentsToDisplayPrice} from "../../../utils/formatting";

function getStatusText(billStatus, bill) {
  const {becameStatusPaymentRequestedAt, becameStatusPaidAt, becameStatusPaymentFailedAt} = bill;
  if (billStatus === "draft") {
    return "The freelancer is still adding sessions to this bill and will request payout later.";
  } else if (billStatus === "pending") {
    return "Your bank is verifying this payment. This may take a few days.";
  } else if (billStatus === "paymentRequested") {
    return `The freelancer requested payment of this bill on ${convertUnixTsToDate(becameStatusPaymentRequestedAt.seconds).toLocaleDateString()}`;
  } else if (billStatus === "paid") {
    return `This bill has been paid ${becameStatusPaidAt ? ` on ${convertUnixTsToDate(becameStatusPaidAt.seconds).toLocaleDateString()}` : ''}`;
  } else if (billStatus === "paymentFailed") {
    return `An attempt to pay this bill failed${becameStatusPaymentFailedAt ? ` on ${convertUnixTsToDate(becameStatusPaymentFailedAt.seconds).toLocaleDateString()}` : ''}. Fix your payment method in your business setting and try again.`;
  } else {
    return "";
  }
}

function getSalesTaxAmount(amountExcl, salesTax) {
  if (salesTax.option === "setTax") {
    return Math.round(amountExcl * (salesTax.rate / 100))
  }
  return 0;
}

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    marginBottom: theme.spacing(2)
  },
  cardContentContainer: {
    display: 'flex',
    justifyContent: "space-between",
    padding: theme.spacing(2)
  },
  cardHeaderRoot: {
    padding: 0,
    minWidth: 180,
    // marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    float: "right",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

function useFetchStripeCustomer(stripeCustomerId) {
  const [value, setValue] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (stripeCustomerId) {
      retrieveCustomer(stripeCustomerId)
        .then(customer => {
          setValue(customer);
          setLoading(false);
        })
    } else {
      setValue(null);
      setLoading(false)
    }
  }, [stripeCustomerId]);

  return [value, loading];
}

export default function BillHeader(props) {
  const {bill, sessions, freelancer, team} = props;
  const [billStatus, setBillStatus] = React.useState(String(bill.status));
  const [disputeDialogOpen, setDisputeDialogOpen] = React.useState(false);
  const [isPaying, setIsPaying] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => setBillStatus(bill.status), [bill.status]);

  const classes = useStyles(props);
  const theme = useTheme();
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);
  const firebase = React.useContext(FirebaseContext);
  const {selectedBusiness} = React.useContext(BusinessContext);
  const [customer, loadingCustomer] = useFetchStripeCustomer(selectedBusiness.stripeCustomerId);
  const stripe = useStripe();

  const handleToggleDisputeDialog = () => setDisputeDialogOpen(cp => !cp);
  const handleCloseErrorDialog = () => setErrorMessage(null);

  const handlePayBill = () => {
    setIsPaying(true);
    const {percentage, amount} = getEstTransactionFee(customer.invoice_settings.default_payment_method, bill.totalAmount);
    const transactionFeeSalesTaxAmount = getSalesTaxAmount(amount, bill.transactionFee.salesTax);
    const transactionFee = {
      ...bill.transactionFee,
      rate: percentage * 100,
      amountExcl: Math.round(amount),
      totalAmount: Math.round(amount + transactionFeeSalesTaxAmount),
      salesTax: {
        ...bill.transactionFee.salesTax,
        amount: Math.round(transactionFeeSalesTaxAmount),
      }
    };

    firebase.bill(bill.uid)
      .update({
        transactionFee,
        lastUpdatedAt: firebase.createTimestamp()
      });
    const piPayload = {
      amount: bill.totalAmount,
      metadata: {
        billId: bill.uid,
      },
      currency: bill.currency,
      customer: customer.id,
      paymentMethodId: customer.invoice_settings.default_payment_method.id,
      stripeAccountId: freelancer.stripeAccountId,
      applicationFee: bill.serviceFee.totalAmount + transactionFee.totalAmount,
    };
    const pmType = customer.invoice_settings.default_payment_method.type;
    createPaymentIntent(piPayload)
      .then(paymentIntent => {
        console.log('paymentIntent 1', paymentIntent);
        if (paymentIntent && pmType === "card" && paymentIntent.client_secret) {
          return stripe
            .confirmCardPayment(paymentIntent.client_secret, {
              payment_method: paymentIntent.payment_method,
              // receipt_email: authUser.email,
            });
        } else if (paymentIntent && pmType === "sepa_debit" && paymentIntent.client_secret) {
          return stripe
            .confirmSepaDebitPayment(paymentIntent.client_secret)
        }
        return {
          error: {
            message: "Payment failed due to an unknown error"
          }
        }
      })
      .then(({paymentIntent, error}) => {
        // Also show paid status when processing. If bill still not paid later
        console.log('paymentIntent 2', paymentIntent);
        if (error) {
          const {message} = error;
          setBillStatus("paymentFailed");
          setErrorMessage(message);
        } else if (paymentIntent.status === "pending") {
          setBillStatus("pending");
        } else if (paymentIntent.status === "processing") {
          // Hot-fix: Should be "processing" but gets stuck for SEPA
          setBillStatus("pending");
        } else if (paymentIntent.status === "succeeded") {
          setBillStatus("paid");
        }

        // Track the payment event (client-side conversion)
        const eventName = ["pending", "processing", "succeeded"].includes(paymentIntent.status)
          ? "payment_initiated"
          : "payment_failed"

        // Todo: Convert amount to EUR
        trackEvent(eventName, { 
          billId: bill.uid,
          minutes: bill.minutes,
          amountExcl: formatCentsToDisplayPrice(bill.amountExcl),
        })

        setIsPaying(false);
      })
  };

  const noBillActions = billStatus === "draft" || billStatus === "pending";
  return (
    <div>
      <SimpleDialog
        id="bill-dispute-dialog"
        toggleDialog={handleToggleDisputeDialog}
        open={disputeDialogOpen}
        title="File a dispute for this bill"
        description="Please email your dispute reason to stijn@maxer.io."
        primaryActionText="OK"
        handlePrimaryAction={handleToggleDisputeDialog}
      />
      {errorMessage && (
        <SimpleDialog
          id="bill-error-dialog"
          toggleDialog={handleCloseErrorDialog}
          open={disputeDialogOpen}
          title="An error occurred"
          description={errorMessage}
          primaryActionText="OK"
          handlePrimaryAction={handleCloseErrorDialog}
        />
      )}
      <Card className={classes.cardRoot}>
        <CardContent className={classes.cardContentContainer}>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
            }}
            avatar={
              (freelancer.profilePhoto) ? (
                <Avatar src={freelancer.profilePhoto} className={classes.avatar}/>
              ) : (
                <Avatar className={classes.avatar}>
                  {freelancer.name.substring(0, 1)}
                </Avatar>
              )
            }
            title={
              <Typography variant="subtitle1" component="h1">
                {`${freelancer.firstName} ${freelancer.lastName}`}
              </Typography>
            }
            disableTypography
            // titleTypographyProps={{variant: "body2", component: "h1"}}
            subheader={
              <Typography
                variant="caption">{`Contracted team ${team.teamLeadId === bill.ownerId ? "lead" : "member"} of `}
                <Link
                  color="primary"
                  to={
                    queryString.stringifyUrl({
                      url: routes.clientTeamsContract.replace(":contractId", bill.contractId),
                      query: parsedQueries,
                    })
                  }
                >
                  {team.name}
                </Link>
              </Typography>}
          />
          <StatusLabel
            value={billStatus}
            shape="square"
            color={theme.palette.statusColors[billStatus]}
            popoverText={getStatusText(billStatus, bill)}
          />
        </CardContent>
        {/*<Divider />*/}
        {/*<CardContent className={classes.cardContentContainer}>*/}
        {/*  <Typography*/}
        {/*    variant="body2"*/}
        {/*    className={classes.cardText}*/}
        {/*  >*/}
        {/*    {getStatusText(bill)}*/}
        {/*  </Typography>*/}
        {/*</CardContent>*/}
        {!noBillActions && (
          <Divider/>
        )}
        {!noBillActions && (
          <CardActionsContainer
            loading={loadingCustomer}
            hasDefaultPaymentMethod={Boolean(customer && customer.invoice_settings.default_payment_method)}
            bill={bill}
            sessions={sessions}
            billStatus={billStatus}
            isPaying={isPaying}
            handlePayBill={handlePayBill}
            businessSettingsRoute={
              queryString.stringifyUrl({
                url: routes.clientSettingsTab.replace(":tab", "business"),
                query: parsedQueries,
              })
            }
            handleToggleDisputeDialog={handleToggleDisputeDialog}
          />
        )}
      </Card>
    </div>
  )
};

BillHeader.propTypes = {
  bill: PropTypes.object.isRequired,
};


