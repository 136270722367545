// React
import React from "react";
import PropTypes from "prop-types";
// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
// Libs
import AvatarEditor from "react-avatar-editor";

const imgWidth = 200;

const useStyles = makeStyles((theme) => ({
  button: {},
  inputContainerWithImg: {
    marginBottom: 10,
    [theme.breakpoints.up("md")]: {
      paddingTop: 65,
    },
  },
  inputContainerNoImg: {
    marginBottom: 10,
    [theme.breakpoints.up("md")]: {
      paddingTop: 25,
    },
  },
  input: {
    display: "none",
  },
  sliderContainer: {
    display: "flex",
  },
  slider: {
    marginLeft: 5,
    marginTop: 25,
    width: imgWidth,
  },
  linearProgressBar: {
    height: 20,
  },
}));

export default function View(props) {
  const {
    setEditorRef,
    handleSelectPhoto,
    profilePhoto,
    handleChange,
    zoom,
    showSliderContainer,
    helperCaption,
    avatarBorderRadius
  } = props;
  const classes = useStyles();

  const opacityOverProfileRadius = showSliderContainer ? 0.6 : 1.0;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <div
            className={
              profilePhoto
                ? classes.inputContainerWithImg
                : classes.inputContainerNoImg
            }
          >
            <input
              id="contained-button-file"
              accept="image/*"
              className={classes.input}
              type="file"
              onChange={handleSelectPhoto}
            />
            <label htmlFor="contained-button-file">
              <Button
                id="select-img-btn"
                variant="contained"
                component="span"
                className={classes.button}
              >
                Select an image
              </Button>
            </label>
            {helperCaption && (
              <FormHelperText>
                {helperCaption}
              </FormHelperText>
            )}
          </div>
        </Grid>
        {profilePhoto && (
          <Grid item xs={12} md={7}>
            <AvatarEditor
              ref={setEditorRef}
              image={profilePhoto}
              width={imgWidth}
              height={imgWidth}
              border={1}
              borderRadius={avatarBorderRadius || 100000}
              color={[255, 255, 255, opacityOverProfileRadius]}
              scale={zoom}
              rotate={0}
              style={{
                cursor: showSliderContainer ? "grab" : "default",
              }}
            />
            {showSliderContainer && (
              <div className={classes.sliderContainer}>
                <Slider
                  className={classes.slider}
                  value={zoom}
                  step={0.0000001}
                  min={1}
                  max={3}
                  onChange={handleChange}
                />
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

View.propTypes = {
  setEditorRef: PropTypes.func.isRequired,
  handleSelectPhoto: PropTypes.func.isRequired,
  profilePhoto: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleChange: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
  showSliderContainer: PropTypes.bool.isRequired,
  avatarBorderRadius: PropTypes.number,
};
