// All utils for manipulating/enhancing colors

export function hasLengthBetween(val, minLength, maxLength) {
  return Boolean(val.length >= minLength && val.length <= maxLength);
}

// Foun here: https://stackoverflow.com/a/46645497/3793914.
// This does not work for objects with circular references
export function isEqual(val1, val2) {
  return Boolean(JSON.stringify({a: val1}) === JSON.stringify({a: val2}));
}
