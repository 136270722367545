// All utils for manipulating/enhancing colors

export function getMinutesBetweenTimestamps(firstTs, secondTs) {
  const secondsBetween = secondTs - firstTs;
  return secondsBetween / 60;
}

//const hoursBetween = Math.round(getHoursBetweenTimestamps(firstTs, secondTs));

export function getHoursBetweenText(hoursBetween) {
  const daysBetween = Math.round(hoursBetween / 24);
  return hoursBetween < 24
    ? `${
      Math.round(hoursBetween) === 1
        ? "1 hour"
        : Math.round(hoursBetween) + " hours"
    } ago`
    : `${daysBetween} day${daysBetween === 1 ? "" : "s"} ago`;
}

export function convertMinToTimeString(minutes) {
  const positiveMinutes = Math.abs(minutes);
  const hours = Math.floor(positiveMinutes / 60);
  const minutesLeft = positiveMinutes % 60;
  let str = "";
  if (hours > 0) {
    str = `${hours} hour${hours > 1 ? "s" : ""}`;
  }
  if (hours === 0 && minutesLeft === 0) {
    str = "0 minutes";
  } else if (minutesLeft > 0) {
    str += `${hours > 0 ? " and " : ""}${minutesLeft} minute${minutesLeft > 1 ? "s" : ""}`;
  }
  return str;
}

export function convertUnixTsToDate(unixTsInSeconds) {
  const unixTsInMs = unixTsInSeconds * 1000;
  return new Date(unixTsInMs);
}

export function alterDate(dayAlteration) {
  const today = new Date();
  const newDate = new Date(today);
  newDate.setDate(newDate.getDate() + dayAlteration);
  return newDate;
}

