export const freelancer = {
  id: "freelancer",
  supportedCountries: [
    "US",
    // EURO countries in Europe
    "AT",
    "BE",
    "EE",
    "FI",
    "FR",
    "DE",
    "GR",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "NL",
    "PT",
    "SK",
    "SI",
    "ES",
    // Non-EUR countries in europe,
    "GB",
    "DK",
    "NO",
    "PL",
    "SE",
    "CH",
  ],
  // Pulled from Stripe's supported settlement currencies: https://stripe.com/docs/connect/payouts#supported-settlement
  supportedCurrencies: ["EUR", "DKK", "GBP", "NOK", "SEK", "USD", "CHF"],
};

export const manager = {
  id: "manager",
  // All currencies supported by the exchanges rates api can be used to pay project
  // Source: https://api.exchangeratesapi.io/latest
  supportedCurrencies: [
    "EUR",
    "USD",
    "JPY",
    "BGN",
    "CZK",
    "DKK",
    "GBP",
    "HUF",
    "PLN",
    "RON",
    "SEK",
    "CHF",
    "ISK",
    "NOK",
    "HRK",
    "RUB",
    "TRY",
    "AUD",
    "BRL",
    "CAD",
    "CNY",
    "HKD",
    "IDR",
    "ILS",
    "INR",
    "KRW",
    "MXN",
    "MYR",
    "NZD",
    "PHP",
    "SGD",
    "THB",
    "ZAR",
  ],
};
