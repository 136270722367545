// React
import React from "react";
import PropTypes from "prop-types";

// MUI
import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {getCurrencyFormat} from "../../utils/formatting";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    width: '100%',
    marginTop: topGutterSpacing ? theme.spacing(topGutterSpacing) : 0,
    marginBottom: bottomGutterSpacing ? theme.spacing(bottomGutterSpacing) : 0,
  }),
  // table: {
  //   minWidth: 750,
  // },
  tableRow: ({onRowClick}) => ({
    "&:hover": {
      cursor: onRowClick ? "pointer" : "inherit",
    },
  }),
  tableCell: {
    opacity: 0.6
  }
}));

function showDateTimeOnly(date) {
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

function getSalesTaxCaption({option, rate, type}) {
  if (option === "setTax") {
    return `${rate}% ${type}`
  } else if (option === "reverseCharge") {
    return "Reverse charged"
  } else {
    return null
  }
}

export default function BillSessionsTable(props) {
  const {sessions, onRowClick, bill} = props;
  const classes = useStyles(props);
  const billTaxCaption = getSalesTaxCaption(bill.salesTax);
  return (
    <div>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {["Description", "Time slot", "Billed time", "Hourly rate", "Amount"]
                .map(label => <TableCell key={label} className={classes.tableCell}>{label}</TableCell>)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map((session, idx) => {
              const {startedDate, endedDate, minutes, minutesBreak, amountExcl} = session;
              const minutesBilled = minutes - (minutesBreak || 0);
              return (
                <TableRow
                  key={`session-${idx}`}
                  onClick={() => onRowClick ? onRowClick(session) : undefined}
                  hover={Boolean(onRowClick)}
                  className={classes.tableRow}
                >
                  <TableCell component="th" scope="row">
                    {session.description || ""}
                  </TableCell>
                  <TableCell style={{minWidth: 120}}>
                    <div>
                      <Typography variant="body2" component="p">{startedDate.toLocaleDateString()}</Typography>
                      <Typography
                        variant="caption"
                        component="p"
                      >
                        {`${showDateTimeOnly(startedDate)}-${showDateTimeOnly(endedDate)}`}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell style={{minWidth: 120}}>
                    <div>
                      <Typography variant="body2" component="p">{`${minutesBilled} min`}</Typography>
                      {minutesBreak > 0 && (
                        <Typography variant="caption" component="p">{`${minutesBreak} min break`}</Typography>
                      )}
                    </div>
                  </TableCell>
                  <TableCell style={{minWidth: 110}}>
                    {getCurrencyFormat(bill.hourlyRate, bill.currency)}
                  </TableCell>
                  <TableCell>
                    {getCurrencyFormat(amountExcl, bill.currency)}
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell rowSpan={3} colSpan={3}/>
              <TableCell colSpan={1}>Amount excl.</TableCell>
              <TableCell colSpan={1}>
                {bill.processing ? (
                  <Skeleton/>
                ) : (
                  getCurrencyFormat(
                    bill.amountExcl, 
                    bill.currency, 
                    {convertToDecimals: true}
                  )
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={1}>
                <div>
                  <Typography variant="body2">Tax</Typography>
                  {billTaxCaption && <Typography variant="caption">{billTaxCaption}</Typography>}
                </div>
              </TableCell>
              <TableCell colSpan={1}>
                {bill.processing ? (
                  <Skeleton />
                ) : (
                  getCurrencyFormat(
                    bill.salesTax.amount, 
                    bill.currency, 
                    {convertToDecimals: true}
                  )
                )}
              </TableCell>
              
            </TableRow>
            <TableRow>
              <TableCell colSpan={1}>Total amount</TableCell>
              <TableCell colSpan={1}>
                {bill.processing ? (
                  <Skeleton />
                ) : (
                  getCurrencyFormat(
                    bill.totalAmount, 
                    bill.currency, 
                    {convertToDecimals: true}
                  )
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

BillSessionsTable.propTypes = {
  bill: PropTypes.object.isRequired,
  sessions: PropTypes.array.isRequired,
};
