import React from "react";
import {
  Document,
  Font,
  Page,
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

// Components
import InvoiceHeader from "./InvoiceHeader";
import CompanyInfo from "./CompanyInfo";
import InvoiceTable from "./InvoiceTable";
import InvoiceBalance from "./InvoiceBalance";
import poweredByMaxer from "./img/powered-by-maxer.png";

// Utils
import {convertMinToTimeString, convertUnixTsToDate} from "../../../utils/time";
import {getCurrencyFormat} from "../../../utils/formatting";

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  companiesInfoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    position: "absolute",
    bottom: 30,
    left: 200,
    flexDirection: "row",
    // marginTop: 20,
    width: 200,
    height: 32,
    // marginLeft: 'auto',
    // marginRight: 'auto'
  },
});

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v13/v0SdcGFAl2aezM9Vq_aFTQ.ttf`,
});

function sumArray(total, num) {
  return total + num;
}

function showDateTimeOnly(date) {
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export default function InvoicePdf(props) {
  const {
    issuedFromDetails,
    issuedToDetails,
    bill,
    sessions,
    feeType,
  } = props;

  const isMaxerInvoice = Boolean(feeType === "platformFees" || feeType === "fees");

  // Invoice number
  const invoiceNumber = `${isMaxerInvoice ? "max_fee" : "fre_fee"}_${
    bill.uid
  }`;

  // Invoice title
  const invoiceTitle = isMaxerInvoice ? "Maxer invoice" : "Freelancer invoice";

  // Item rows
  let rows = [];
  if (isMaxerInvoice) {
    rows.push({
      description: `${bill.transactionFee.rate}% transaction fee over inclusive amount of bill #${bill.uid}`,
      // amount: formatCentsToDisplayPrice(bill.transactionFee.amountExcl),
      amount: bill.transactionFee.amountExcl,
      currency: bill.currency
    });
    rows.push({
      description: `${bill.serviceFee.rate}% service fee over exclusive amount of bill #${bill.uid}`,
      // amount: formatCentsToDisplayPrice(bill.serviceFee.amountExcl),
      amount: bill.serviceFee.amountExcl,
      currency: bill.currency,
    });
  } else {
    rows = sessions
      .map(({startedDate, endedDate, minutes, minutesBreak, amountExcl, description}) => ({
        description: `${startedDate.toLocaleDateString()} ${showDateTimeOnly(startedDate)}-${showDateTimeOnly(endedDate)}: ${convertMinToTimeString(minutes)}${minutesBreak > 0 ? ` with a ${minutesBreak} minutes break` : ''} at ${getCurrencyFormat(bill.hourlyRate, bill.currency)}/hour`,
        // amount: formatCentsToDisplayPrice(amountExcl),
        amount: amountExcl,
        currency: bill.currency,
      }));
    // rows.push({
    //   description: `${convertMinToTimeString(minutesBilled)} billed`,
    //   amount: formatCentsToDisplayPrice(bill.amountExcl)
    // });
  }

  // Balance variables
  const subTotal = rows.map(({amount}) => amount).reduce(sumArray);
  const taxOption = isMaxerInvoice
    ? bill.serviceFee.salesTax.option
    : bill.salesTax.option;
  const taxReverseCharged = Boolean(taxOption === "reverseCharge");
  const salesTaxRate = parseInt(
    isMaxerInvoice
      ? bill.serviceFee.salesTax.rate
      : bill.salesTax.rate
  );
  const taxRateText = taxOption === "setTax" ? `${salesTaxRate}%` : "None";
  const salesTaxAmount = taxOption === "setTax" ? subTotal * (salesTaxRate / 100) : 0;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceHeader
          title={invoiceTitle}
          invoiceNr={invoiceNumber}
          invoiceDate={convertUnixTsToDate(bill.becameStatusPaymentRequestedAt.seconds).toLocaleDateString()}
        />
        <View style={styles.companiesInfoContainer}>
          <CompanyInfo topLabel="Issued from" {...issuedFromDetails} />
          <CompanyInfo topLabel="Issued to" {...issuedToDetails} />
        </View>
        <InvoiceTable rows={rows}/>
        <InvoiceBalance
          currency={bill.currency}
          taxReverseCharged={taxReverseCharged}
          taxRateText={taxRateText}
          subTotal={subTotal}
          salesTaxAmount={salesTaxAmount}
          align="right"
        />
        <Image
          style={styles.logo}
          src={poweredByMaxer}
        />
      </Page>
    </Document>
  );
}