// React
import React from "react";
import PropTypes from "prop-types";

// Components
import UploaderListItem from "./UploaderListItem";
import PortfolioImageDialog from "./PortfolioImageDialog";
import Carousel from "../../../../../Carousel";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SettingsPanel from "../../../../../SettingsPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  imagesHeaderText: {
    marginTop: 20,
  },
}));

export default function ImagesPanel(props) {
  const {
    images,
    indexEnlargedImage,
    onFieldChange,
    onOrderChange,
    onDelete,
    isExpanded,
    handlePanelChange,
  } = props;
  const classes = useStyles();

  return (
    <SettingsPanel
      expanded={isExpanded}
      handlePanelChange={handlePanelChange}
      label="Images"
      expandedText="Upload images showcasing your work (ad copy, creative assets, landing pages, graphs etc.)"
      collapsedText={
        images.length === 1
          ? `1 image uploaded`
          : `${images.length} images uploaded`
      }
    >
      <div>
        <PortfolioImageDialog
          isOpen={Boolean(
            indexEnlargedImage >= 0 && images && images[indexEnlargedImage]
          )}
          imageToEnlarge={images && images[indexEnlargedImage]}
          closeDialog={onFieldChange}
        />
        <UploaderListItem onFieldChange={onFieldChange}/>
        {images.length > 0 && (
          <Typography className={classes.imagesHeaderText}>
            Images
          </Typography>
        )}
        <Carousel
          className={classes.portfolioList}
          items={images}
          handleItemClick={onFieldChange}
          spacing={1}
          height={160}
          changeOrder={onOrderChange}
          deleteItem={onDelete}
        />
        {images.length === 12 && (
          <Typography variant="caption">
            You have added the maximum number of images (12) for this project.
          </Typography>
        )}
      </div>
    </SettingsPanel>
  );
}

ImagesPanel.propTypes = {
  images: PropTypes.array.isRequired,
  indexEnlargedImage: PropTypes.number,
  onOrderChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  handlePanelChange: PropTypes.func.isRequired,
};
