import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";

const borderColor = "#EBEBEB";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 2,
    alignItems: "center",
    // height: 24,
    // textAlign: 'center',
    // fontStyle: 'bold',
    flexGrow: 1,
  },
  firstCell: {
    fontFamily: "Lato",
    fontSize: 10,
    width: "80%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: "10px 10px 5px 10px",
  },
  lastCell: {
    fontFamily: "Lato",
    fontSize: 10,
    width: "20%",
    padding: "10px 10px 5px 10px",
  },
});

export default function InvoiceTableHeader() {
  return (
    <View style={styles.container}>
      <Text style={styles.firstCell}>Description</Text>
      <Text style={styles.lastCell}>Amount</Text>
    </View>
  );
}
