//React
import React from "react";
import PropTypes from "prop-types";

// Components
import SettingsPanel from "../../SettingsPanel";
import View from "./View";

// Other libs
const pica = require("pica")();

const initialState = {
  profilePhoto: null,
  zoom: 1.0,
  showSliderContainer: false,
  isUploading: false,
  uploadProgress: 0.0,
};

class ProfilePhotoPanel extends React.Component {
  state = {...initialState};

  componentDidMount() {
    this.setState({
      profilePhoto: this.props.lastSavedPhoto || null,
      // lastSavedProfilePhoto: lastSavedPhoto || null,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastSavedPhoto !== prevProps.lastSavedPhoto) {
      this.setState({
        profilePhoto: this.props.lastSavedPhoto || null,
      });
    }
  }

  handleSelectPhoto = ({target}) => {
    const file = target.files[0];
    file && this.setState({profilePhoto: file, showSliderContainer: true});
    // Target value needs to be reset so onchange in input gets triggered when:
    //    1. User selects an image
    //    2. User cancels image
    //    3. User reselects same image
    // See: https://github.com/ngokevin/react-file-reader-input/issues/11#issuecomment-363484861
    target.value = "";
  };

  cancelSelectedPhoto = () => {
    const {handlePanelChange, lastSavedPhoto} = this.props;
    this.setState({
      profilePhoto: lastSavedPhoto,
      zoom: 1.0,
      showSliderContainer: false,
    });
    handlePanelChange && handlePanelChange();
  };

  handleUploadPhoto = () => {
    // const { authUser } = this.props;

    this.setState({
      isUploading: true,
      showSliderContainer: false,
    });

    if (this.editor) {
      const originalCanvas = this.editor.getImageScaledToCanvas();

      // Use pica to resize image and paint on destination canvas
      const targetCanvas = document.createElement("canvas");
      targetCanvas.width = 400;
      targetCanvas.height = 400;

      pica
        .resize(originalCanvas, targetCanvas)
        .then((result) => pica.toBlob(result, "image/jpeg", 0.95))
        // .then((blob) => {
        //   const pathRef = `images/profile/${authUser.uid}`;
        //   return this.getDownloadUrl(pathRef, blob);
        // })
        .then((blob) => this.getDownloadUrl(blob))
        .then((url) => this.updateProfilePicture(url));
    }
  };

  getDownloadUrl = (blob) => {
    const {firebaseStorageRef} = this.props;
    const uploadTask = firebaseStorageRef.put(blob);
    return new Promise((resolve, reject) => {
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const uploadProgress =
            snapshot.bytesTransferred / snapshot.totalBytes;
          this.setState({uploadProgress});
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
            default:
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
              break;
          }
        },
        () => {
          //Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((profilePhoto) => {
            resolve(profilePhoto);
          });
        }
      );
    });
  };

  setEditorRef = (editor) => (this.editor = editor);

  updateProfilePicture = (profilePhoto) => {
    const {firebaseRef, handlePanelChange, draftMode} = this.props;
    // Update user profile
    firebaseRef
      .update({profilePhoto})
      .then(() => {
        // Update state to new profile picture. Zoomout completely.
        !draftMode && this.setState({
          isUploading: false,
          zoom: 1.0,
          profilePhoto,
          lastSavedProfilePhoto: profilePhoto,
        });
        handlePanelChange && handlePanelChange();
      });
  };

  handleChange = (event, value) => {
    this.setState({zoom: value});
  };

  render() {
    const {label, expandedText, helperCaption, isExpanded, handlePanelChange, altText, draftMode, avatarBorderRadius, lastSavedPhoto} = this.props;
    const {isUploading, profilePhoto} = this.state;
    return (
      <SettingsPanel
        id="photo-panel"
        expanded={isExpanded}
        handlePanelChange={handlePanelChange}
        label={label || "Photo"}
        expandedText={expandedText || "Set a profile photo"}
        collapsedText={
          this.state.profilePhoto ? (
            <img
              style={{width: 50, borderRadius: 5}}
              src={this.state.profilePhoto}
              alt={altText}
            />
          ) : (
            ""
          )
        }
        onCancel={this.cancelSelectedPhoto}
        onSave={this.handleUploadPhoto}
        isSaving={isUploading}
        unsavedChanges={Boolean(profilePhoto !== lastSavedPhoto)}
        draftMode={draftMode}
        //error={error}
      >
        <View
          setEditorRef={this.setEditorRef}
          handleSelectPhoto={this.handleSelectPhoto}
          profilePhoto={this.state.profilePhoto}
          handleChange={this.handleChange}
          zoom={this.state.zoom}
          showSliderContainer={this.state.showSliderContainer}
          helperCaption={helperCaption}
          avatarBorderRadius={avatarBorderRadius}
        />
      </SettingsPanel>
    );
  }
}

ProfilePhotoPanel.propTypes = {
  firebaseRef: PropTypes.any.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  altText: PropTypes.string.isRequired,
  handlePanelChange: PropTypes.func,
  draftMode: PropTypes.bool,
};

export default ProfilePhotoPanel;
