// React
import React from "react";

// Material ui
import {MenuItem, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(4.5),
    borderRadius: "0.2rem 0rem 0rem 0.2rem",
    // border: `1px solid ${theme.palette.borders}`,
    // background: theme.palette.borders,
  },
  inputRoot: {
    margin: "auto",
  },
  selectRoot: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
  },
  // menuItem: {
  //   background: "red",
  // }
}));

export default function FilterDropdown(props) {
  const {options, value, onChange} = props;
  const classes = useStyles(props);

  return (
    <TextField
      id="standard-select-currency"
      select
      value={value}
      onChange={e => onChange(e.target.value)}
      // className={classes.root}
      classes={{
        root: classes.root
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
        }
      }}
      SelectProps={{
        classes: {root: classes.selectRoot}
      }}
    >
      {options
        .map(({text, value, startIcon}) =>
          <MenuItem
            value={value}
            key={value}
            className={classes.menuItem}
          >
            {startIcon}
            <Typography variant="body2">{text}</Typography>
          </MenuItem>
        )
      }
    </TextField>
  );
}