import {paymentsApi} from "../../constants/apiRoutes";

const defaultOptions = {
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
  referrer: "no-referrer",
};

export function retrieveCustomer(stripeCustomerId) {
  return new Promise((resolve, reject) => {
    fetch(`${paymentsApi}/customers/${stripeCustomerId}`, {
      ...defaultOptions,
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((err) => reject(err))
  });
}

export function createCustomer(business, email) {
  return new Promise((resolve, reject) => {
    const {uid, name, ownerId} = business;
    fetch(`${paymentsApi}/customers`, {
      ...defaultOptions,
      method: "POST",
      body: JSON.stringify({
        businessId: uid,
        businessName: name,
        ownerId,
        email,
      }),
    })
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((err) => reject(err))
  });
}

export function updateDefaultPaymentMethod(customerId, paymentMethodId) {
  return new Promise((resolve, reject) => {
    fetch(`${paymentsApi}/customers/${customerId}/default-payment-method`, {
      ...defaultOptions,
      method: "POST",
      body: JSON.stringify({
        'payment_method_id': paymentMethodId
      }),
    })
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((err) => reject(err))
  });
}
