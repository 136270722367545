// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StatusLabel from "../../../../components/StatusLabel";

// MUI
import {TableRow, TableCell, Avatar, CardHeader } from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';

// Utils
import { getCurrencyFormat } from "../../../../utils/formatting";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardHeaderRoot: {
    padding: 0
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}));

export default function ContractTableRow(props) {
  const {uid, contractId, name, profilePhoto, status, currency, noHireContracts, monthlyBudget, onClick} = props;
  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <TableRow
      hover
      onClick={() => onClick(contractId)}
      tabIndex={-1}
      key={uid}
      className={classes.tableRow}
    >
      <TableCell component="th" id="name" scope="row">
        <CardHeader
          classes={{
            root: classes.cardHeaderRoot,
          }}
          avatar={
            (profilePhoto) ? (
              <Avatar src={profilePhoto} className={classes.avatar} variant="rounded"/>
            ) : (
              <Avatar className={classes.avatar} variant="rounded">
                {name.substring(0, 1)}
              </Avatar>
            )
          }
          title={name}
          titleTypographyProps={{variant: "body2", component: "h1"}}
          // subheader={jobTitle}
        />
      </TableCell>
      <TableCell id="contractStatus">
        <StatusLabel
          color={theme.palette.statusColors[status]}
          value={status}
          size="small"
          shape="square"
          clickable
        />
      </TableCell>
      <TableCell id="noHireContracts">
        {noHireContracts}
      </TableCell>
      <TableCell id="monthlyBudget">
        {getCurrencyFormat(monthlyBudget, currency)}
      </TableCell>
    </TableRow>
  );
}

ContractTableRow.propTypes = {
  uid: PropTypes.string.isRequired,
};



