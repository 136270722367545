// React
import React from "react";
import PropTypes from "prop-types";

// Components
import {AuthUserContext} from "../../components/Session";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import StatusLabel from "../../components/StatusLabel";

// MUI
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Avatar,
  CardHeader,
  Typography
} from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';

// Utils
import {convertUnixTsToDate} from "../../utils/time";
import {stableSort, getSorting} from "../../utils/sorting";
import {getCurrencyFormat} from "../../utils/formatting";

function createHeadCells({authUser}) {
  const headCells = [
    {
      id: "title",
      label: "Title",
    },
    {
      id: "client",
      label: "Client",
    },
    {
      id: "freelancer",
      label: authUser.role === "manager" ? "Freelancer" : "Creator",
    },
    {
      id: "team",
      label: "Team",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "createdAtDate",
      label: "Created at",
    },
    {
      id: "lastSessionDate",
      label: "Last session",
    },
    {
      id: "amountExcl",
      label: "Amount (excl. tax)",
    },
  ];
  return authUser.role === "manager"
    ? headCells.filter(({id}) => id !== "client")
    : headCells;
}

function createRows({bills, clientProfiles, freelancerProfiles, teamProfiles, authUser}) {
  return bills
    .map(({uid, title, ownerId, teamId, status, createdAt, currency, amountExcl, totalAmount, lastSessionAt, businessId}) => {

      const freelancerProfile = freelancerProfiles
        .filter(p => p.uid === ownerId)[0];
      const teamProfile = teamProfiles
        .filter(p => p.uid === teamId)[0];
      const clientProfile = authUser.role === "freelancer"
        ? clientProfiles
          .filter(({business}) => business.uid === businessId)[0]
        : null;

      return ({
        billId: uid,
        title,
        freelancerProfile,
        teamProfile,
        clientProfile,
        status,
        createdAtDate: convertUnixTsToDate(createdAt.seconds),
        lastSessionDate: lastSessionAt ? convertUnixTsToDate(lastSessionAt.seconds) : new Date(1111, 0, 1),
        currency,
        amountExcl,
        totalAmount,
      })
    })
}

const useStyles = makeStyles((theme) => ({
  root: ({topGutterSpacing, bottomGutterSpacing}) => ({
    width: '100%',
    marginTop: topGutterSpacing ? theme.spacing(topGutterSpacing) : 0,
    marginBottom: bottomGutterSpacing ? theme.spacing(bottomGutterSpacing) : 0,
  }),
  table: {
    minWidth: 750,
  },
  tableRow: ({handleRowClick}) => ({
    "&:hover": {
      cursor: handleRowClick ? "pointer" : "inherit",
    },
  }),
  cardHeaderRoot: {
    padding: 0
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}));

export default function BillsTable(props) {
  const {bills, clientProfiles, teamProfiles, freelancerProfiles, handleRowClick} = props;
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('lastSessionDate');
  const [page, setPage] = React.useState(0);
  const classes = useStyles(props);
  const authUser = React.useContext(AuthUserContext);
  const theme = useTheme();
  const rowsPerPage = 10;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const rows = createRows({bills, clientProfiles, freelancerProfiles, teamProfiles, authUser});
  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          size='medium'
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={createHeadCells({authUser})}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(({billId, title, clientProfile, freelancerProfile, teamProfile, createdAtDate, lastSessionDate, status, currency, amountExcl}) => {
                return (
                  <TableRow
                    hover
                    onClick={() => handleRowClick(billId)}
                    key={billId}
                    className={classes.tableRow}
                  >
                    <TableCell component="th" id="title" scope="row" style={{minWidth: 150}}>
                      <Typography variant="caption">
                        {title || `#${billId.substring(0, 10)}...`}
                      </Typography>
                    </TableCell>
                    {clientProfile && (
                      <TableCell>
                        <CardHeader
                          classes={{
                            root: classes.cardHeaderRoot,
                          }}
                          avatar={
                            (clientProfile.profilePhoto) ? (
                              <Avatar src={clientProfile.profilePhoto} className={classes.avatar}/>
                            ) : (
                              <Avatar className={classes.avatar}>
                                {clientProfile.firstName.substring(0, 1)}
                              </Avatar>
                            )
                          }
                          title={`${clientProfile.firstName} ${clientProfile.lastName}`}
                          titleTypographyProps={{variant: "body2", component: "h1"}}
                          subheader={clientProfile.business.name}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <CardHeader
                        classes={{
                          root: classes.cardHeaderRoot,
                        }}
                        avatar={
                          (freelancerProfile.profilePhoto) ? (
                            <Avatar src={freelancerProfile.profilePhoto} className={classes.avatar}/>
                          ) : (
                            <Avatar className={classes.avatar}>
                              {freelancerProfile.firstName.substring(0, 1)}
                            </Avatar>
                          )
                        }
                        title={`${freelancerProfile.firstName} ${freelancerProfile.lastName}`}
                        titleTypographyProps={{variant: "body2", component: "h1"}}
                        // subheader={jobTitle}
                      />
                    </TableCell>
                    <TableCell>
                      <CardHeader
                        classes={{
                          root: classes.cardHeaderRoot,
                        }}
                        avatar={<Avatar src={teamProfile.profilePhoto} className={classes.avatar} variant="rounded"/>}
                        title={teamProfile.name}
                        titleTypographyProps={{variant: "body2", component: "h1"}}
                        // subheader={jobTitle}
                      />
                    </TableCell>
                    <TableCell id="status">
                      <StatusLabel
                        color={theme.palette.statusColors[status]}
                        value={status}
                        size="small"
                        shape="square"
                      />
                    </TableCell>
                    <TableCell id="createdAtDate">
                      <Typography variant="caption">
                        {createdAtDate.toLocaleDateString()}
                      </Typography>
                    </TableCell>
                    <TableCell id="lastSessionDate">
                      <Typography variant="caption">
                        {lastSessionDate.getFullYear() === 1111 ? "--" : lastSessionDate.toLocaleDateString()}
                      </Typography>
                    </TableCell>
                    <TableCell id="amountExcl">
                      {getCurrencyFormat(amountExcl, currency, {convertToDecimals: true})}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={bills.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
      />
    </div>
  );
}

BillsTable.propTypes = {
  bills: PropTypes.array.isRequired,
  budgetAvailable: PropTypes.number,
  budgetBilled: PropTypes.number,
};
