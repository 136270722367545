//React
import React from "react";
import PropTypes from "prop-types";

// Components
import SettingsPanel from "../SettingsPanel";

// Context
import {AuthUserContext} from "../Session";

// Constants
import {skillOptions} from "../../constants/skills";

// Utils
import {isEqual} from "../../utils/validators";

// Material UI
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FirebaseContext } from "../Firebase";

function getCollapsedText(skills) {
  const fullText = skillOptions.length > 0 ? (
    skillOptions
      .filter(({value}) => skills.includes(value))
      .map(({label}) => label)
      .join(", ")
  ) : "";
  if (fullText.length > 100) {
    return `${fullText.substring(0, 100)}...`
  }
  return fullText;
}

export default function SkillsPanel(props) {
  const {value, firebaseRef, isExpanded, handlePanelChange, draftMode} = props;
  const firebase = React.useContext(FirebaseContext);
  const authUser = React.useContext(AuthUserContext);
  console.log("value xxx", value)
  const [skills, setSkills] = React.useState(value || []);
  const [isSaving, setIsSaving] = React.useState(false);


  console.log("skills xxx", value)


  const handleChange = options => setSkills(options.map(({value}) => value));

  const handleCancel = () => {
    setSkills(value || []);
    handlePanelChange && handlePanelChange();
  };

  const handleSave = () => {
    setIsSaving(true);
    firebaseRef
      .update({
        lastUpdatedAt: firebase.createTimestamp(),
        skills
      })
      .then(() => {
        if (!draftMode) {
          setIsSaving(false);
        }
        handlePanelChange && handlePanelChange();
      });
  };

  const expandedText = authUser.role === "manager"
    ? "Add the skills you would like to hire for"
    : "Add your most relevant skills";
  const maxSkills = authUser.role === "freelancer" ? 10 : 100;
  return (
    <SettingsPanel
      id="languages-panel"
      expanded={isExpanded}
      handlePanelChange={handlePanelChange}
      label="Skills"
      expandedText={expandedText}
      collapsedText={getCollapsedText(skills)}
      onCancel={handleCancel}
      onSave={handleSave}
      isSaving={isSaving}
      unsavedChanges={!isEqual(skills, value)}
      draftMode={draftMode}
      //error={error}
    >
      <Autocomplete
        id="skills-select"
        multiple
        options={skills.length >= maxSkills ? [] : skillOptions}
        disableCloseOnSelect
        autoHighlight
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label || ""}
        value={skillOptions.filter(({value}) => skills.includes(value))}
        onChange={(event, option) => handleChange(option)}
        renderOption={(option) => (
          <React.Fragment>{option.label}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Skills"
            variant="outlined"
            fullWidth
            helperText={skills.length >= maxSkills ? `You have reached the maximum number of skills (${maxSkills})` : null}
          />
        )}
      />
    </SettingsPanel>
  );
}

SkillsPanel.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  handlePanelChange: PropTypes.func,
};
