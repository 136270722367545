import {paymentsApi} from "../../constants/apiRoutes";
import axios from "axios";

export function retrievePaymentMethods(businessId, idToken) {
  return new Promise((resolve, reject) => {
    const payload = {
      id_token: idToken,
    };
    return axios
      .post(`${paymentsApi}/payment-methods/${businessId}`, payload)
      .then((result) => resolve(result.data))
      .catch((error) => reject(error));
  });
}
