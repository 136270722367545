// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {makeStyles} from '@material-ui/core/styles';
import FilterBar from "../../components/FilterBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "space-between",
  },
  submitBtn: {
    minWidth: 90
  }
}));

export default function ClientBillsToolbar(props) {
  const {statusOptions, handleSearch, filters, handleSelectFilter} = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FilterBar
        searchQueryPlaceholder="Search for bills"
        onSearch={handleSearch}
        filterOneLabel="Status"
        filterOneOptions={statusOptions}
        filterOneValue={filters.statusFilter}
        onFilterOneChange={(value) => handleSelectFilter("statusFilter", value)}
      />
      {/*<Button*/}
      {/*  variant="contained"*/}
      {/*  color="primary"*/}
      {/*  startIcon={*/}
      {/*    creatingBill*/}
      {/*      ? <CircularProgress className={classes.progressIndicator} size={20}/>*/}
      {/*      : <AddIcon className={classes.addBtnIcon}/>*/}
      {/*  }*/}
      {/*  className={classes.button}*/}
      {/*  onClick={handleCreateBill}*/}
      {/*  disabled={creatingBill}*/}
      {/*>*/}
      {/*  New bill*/}
      {/*</Button>*/}
    </div>
  );
};

ClientBillsToolbar.propTypes = {
  minutesSelected: PropTypes.number,
};