import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";
import InfoElement from "./InfoElement";

const styles = StyleSheet.create({
  root: {
    marginTop: 20,
  },
  topLabel: {
    marginBottom: 10,
    fontSize: 12,
    fontFamily: "Lato",
  },
});

function createRows(
  fullName,
  businessName,
  addressLineOne,
  addressLineTwo,
  city,
  zip,
  country,
  taxNumber
) {
  const rows = [];
  if (fullName) {
    rows.push({
      id: "name",
      label: "Attn",
      value: fullName,
    });
  }
  rows.push({
    id: "company",
    label: "Business name",
    value: businessName,
  });
  rows.push({
    id: "addressLineOne",
    label: "Address line 1",
    value: addressLineOne,
  });
  if (addressLineTwo && addressLineTwo !== "") {
    rows.push({
      id: "addressLineTwo",
      label: "Address line 2",
      value: addressLineTwo,
    });
  }
  rows.push({
    id: "cityAndZip",
    label: `City/Zip`,
    value: `${city}, ${zip}`,
  });
  rows.push({
    id: "country",
    label: `Country`,
    value: country,
  });
  if (taxNumber) {
    rows.push({
      id: "taxNumber",
      label: "Tax number",
      value: taxNumber,
      topGutter: true,
    });
  }
  return rows;
}

export default function CompanyInfo(props) {
  const {
    topLabel,
    fullName,
    addressLineOne,
    addressLineTwo,
    businessName,
    city,
    country,
    postcode,
    taxNumber,
  } = props;
  const rows = createRows(
    fullName,
    businessName,
    addressLineOne,
    addressLineTwo,
    city,
    postcode,
    country,
    taxNumber
  );

  return (
    <View style={styles.root} key={topLabel}>
      <Text style={styles.topLabel}>{topLabel}</Text>
      {rows.map((row) => (
        <InfoElement {...row} key={row.id}/>
      ))}
    </View>
  );
}
