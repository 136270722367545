// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import {
  LinearProgress,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core'
import {ExpandMore, ChevronRight} from "@material-ui/icons";

// Other libs
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "default",
    "&:hover": {
      cursor: "default",
    },
  },
  draftModeContainer: {
    padding: theme.spacing(2)
  },
  rounded: {
    cursor: "default",
    "&:hover": {
      cursor: "default",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "20.0%",
    flexShrink: 0,
  },
  draftHeading: {
    marginBottom: theme.spacing(2)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  notClickable: {
    cursor: "default",
  },
  summaryDefault: {
    cursor: "default",
    margin: "auto",
  },
  summaryLarge: {
    cursor: "default",
    margin: "auto",
    paddingTop: 15,
    paddingBottom: 15,
  },
  childComponent: ({draftMode}) => ({
    width: "100%",
    paddingLeft: draftMode ? theme.spacing(0) : "20.0%",
    paddingRight: draftMode ? theme.spacing(0) : "10.0%",
  }),
  linearProgressCollapsed: {
    marginTop: 10,
    width: "100%",
    marginRight: "10%",
  },
  panelActionsLoadingWrapper: {
    paddingRight: 50,
  },
  panelActionsLoading: {
    display: "inherit",
  },
  savingText: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  errorColor: {
    color: "red",
  },
}));

function PanelActionsLoading(classes) {
  return (
    <AccordionActions
      className={classes.panelActionsLoading}
      style={{marginRight: 12}}
    >
      <Typography className={classes.savingText}>Saving changes...</Typography>
      <CircularProgress size={24}/>
    </AccordionActions>
  );
}

function PanelActions(props) {
  const {onCancel, onBack, onSave, disableSave, draftMode} = props;
  return (
    <AccordionActions>
      {onBack && (
        <Button
          id="back-btn"
          size="small"
          onClick={() => onBack()}
        >
          Back
        </Button>
      )}
      {!draftMode && onCancel && (
        <Button
          id="cancel-btn"
          size="small"
          onClick={() => onCancel()}
          disabled={disableSave}
        >
          Cancel
        </Button>
      )}
      <Button
        id="save-btn"
        size="small"
        color="primary"
        onClick={() => onSave()}
        disabled={disableSave}
      >
        {draftMode ? "Save and continue" : "Save"}
      </Button>
    </AccordionActions>
  );
}

export default function SettingsPanel(props) {
  const {
    id,
    label,
    handlePanelChange,
    draftMode,
    expanded,
    isLoading,
    expandDirection,
    expandedText,
    collapsedText,
    onBack,
    onCancel,
    onSave,
    isSaving,
    unsavedChanges,
    error,
    errorMessage,
    isDisabled,
    skippable
  } = props;
  const classes = useStyles(props);

  if (draftMode) {
    return (
      <Paper className={classes.draftModeRoot}>
        <div className={classes.draftModeContainer}>
          <Typography className={classes.draftHeading}>{expandedText}</Typography>
          {isLoading ? (
            <LinearProgress className={classes.linearProgressCollapsed}/>
          ) : (
            <div className={classes.childComponent}>
              {error ? (
                <Typography
                  className={classNames(
                    classes.secondaryHeading,
                    error ? classes.errorColor : classes.noErrorColor
                  )}
                >
                  {errorMessage}
                </Typography>
              ) : props.children}
            </div>
          )}
        </div>
        {onCancel && onSave && (
          <div>
            <Divider/>
            {isSaving ? (
              <PanelActionsLoading/>
            ) : (
              <PanelActions
                onBack={onBack}
                onSave={onSave}
                skippable={skippable}
                disableSave={!unsavedChanges && !skippable}
                draftMode={draftMode}
              />
            )}
          </div>
        )}
      </Paper>
    )
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handlePanelChange}
      id={id || "settings-panel"}
      disabled={isDisabled}
    >
      {/*<Typography>Testing</Typography>*/}
      <AccordionSummary
        style={{cursor: "pointer"}}
        expandIcon={
          expandDirection === "right" ? (
            <ChevronRight/>
          ) : (
            <ExpandMore/>
          )
        }
        className={
          expandDirection === "right"
            ? classes.summaryLarge
            : classes.summaryDefault
        }
      >
        <Typography className={classes.heading}>{label}</Typography>
        {expanded ? (
          <div>
            <Typography className={classes.secondaryHeading}>
              {expandedText}
            </Typography>
            <Typography variant="caption" className={classes.errorColor}>
              {errorMessage}
            </Typography>
          </div>
        ) : isLoading ? (
          <LinearProgress className={classes.linearProgressCollapsed}/>
        ) : (
          <Typography
            className={classNames(
              classes.secondaryHeading,
              error ? classes.errorColor : classes.noErrorColor
            )}
          >
            {collapsedText}
          </Typography>
        )}
      </AccordionSummary>

      <AccordionDetails className={classes.details}>
        <div className={classes.childComponent}>
          {!isLoading && props.children && props.children}
        </div>
      </AccordionDetails>
      {onCancel && onSave && (
        <div>
          <Divider/>
          {isSaving ? (
            <PanelActionsLoading/>
          ) : (
            <PanelActions
              onCancel={onCancel}
              onSave={onSave}
              disableSave={Boolean(!unsavedChanges)}
            />
          )}
        </div>
      )}
      {isLoading && <LinearProgress className={classes.linearProgress}/>}
    </Accordion>
  );
}

SettingsPanel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  handlePanelChange: PropTypes.func,
  expanded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  expandDirection: PropTypes.string,
  expandedText: PropTypes.string,
  collapsedText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  isSaving: PropTypes.bool,
  unsavedChanges: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};
