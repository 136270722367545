//React
import React from "react";
import PropTypes from "prop-types";

// Services
import {fetchCountries} from "../../services/countries";

// Utils
import {countryToFlag} from "../../utils/formatting";

// Components
import SettingsPanel from "../SettingsPanel";

// Material UI
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

export default function CountryPanel(props) {
  const {firebaseRef, value, handlePanelChange, isExpanded, draftMode, canMakeEdits} = props;
  const [countries, setCountries] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(value);
  const [lastSavedCountry, setLastSavedCountry] = React.useState(value);
  const [isFetching, setIsFetching] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    setIsFetching(true);
    fetchCountries()
      .then((res) => {
        const formatted = res
          .map(({cca2, name}) => ({
            value: cca2,
            label: name.common,
          }));
        setCountries(formatted);
        setIsFetching(false);
      });
  }, []);

  const handleChange = (option, value) => setSelectedCountry(value);

  const handleCancel = () => {
    setSelectedCountry(lastSavedCountry);
    handlePanelChange && handlePanelChange();
  };

  const handleSave = () => {
    handlePanelChange && handlePanelChange();
    firebaseRef
      .update({country: selectedCountry})
      .then(() => {
        !draftMode && setLastSavedCountry(selectedCountry);
      });
  };

  const selectedCountryObj = countries.filter(({value}) => value === selectedCountry)[0];
  return (
    <SettingsPanel
      id="country-panel"
      expanded={isExpanded}
      isLoading={isFetching}
      handlePanelChange={handlePanelChange}
      label="Country"
      expandedText="Select the country where your business is registered"
      collapsedText={selectedCountryObj ? selectedCountryObj.label : ""}
      onCancel={canMakeEdits ? handleCancel : null}
      onSave={canMakeEdits ? handleSave : null}
      unsavedChanges={Boolean(selectedCountry !== lastSavedCountry)}
      draftMode={draftMode}
      //error={error}
    >
      <Autocomplete
        id="country-select"
        options={countries}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.label || ""}
        getOptionSelected={(option, value) => option.value === value.value}
        defaultValue={null}
        value={selectedCountryObj || null}
        onChange={(event, option) =>
          handleChange("country", option ? option.value : null)
        }
        renderOption={(option) => (
          <React.Fragment>
            <span>{countryToFlag(option.value)}</span>
            {option.label} ({option.value})
          </React.Fragment>
        )}
        disabled={!canMakeEdits}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Country"
            variant="outlined"
            fullWidth
            helperText={canMakeEdits ? null : `Cannot be edited`}
          />
        )}
      />
    </SettingsPanel>
  );
}

CountryPanel.propTypes = {
  firebaseRef: PropTypes.any.isRequired,
  value: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  handlePanelChange: PropTypes.func,
  draftMode: PropTypes.bool,
};
