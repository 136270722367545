// The free exchangeratesapi is used: https://exchangeratesapi.io/
// This API is supported by the European central bank and supports 33 currencies

export function fetchEurExchangeRates() {
  return new Promise((resolve, reject) => {
    fetch(`https://api.exchangeratesapi.io/latest?access_key=${process.env.GATSBY_EXCHANGE_RATES_API_TOKEN}`)
      .then((response) => response.json())
      .then((result) => {
        const {rates, success, error} = result;
        if (success) {
          resolve({
            // Base currency is euro so conversion rate here is 1
            EUR: 1,
            ...rates,
          });
        } else {
          throw error.info;
        }

      })
      .catch((error) => reject(error));
  });
}

export function convertAmount(amount, fromEurExchangeRate, toEurExchangeRate) {
  const amountInEur = amount / fromEurExchangeRate;
  return amountInEur * toEurExchangeRate;
}
