export function fetchCountries() {
  return new Promise((resolve, reject) => {
    // const apiUrl = `https://restcountries.com/v3/all?fields=name;currencies;alpha2Code;regionalBlocs`
    const apiUrl = 'https://restcountries.com/v3/all?fields=name,currencies,cca2'
    fetch(apiUrl)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
