import React from "react";
import {Text, View, StyleSheet} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    width: 200,
  },
  label: {
    fontFamily: "Lato",
    fontSize: 10,
    color: "#666666",
    width: 100,
  },
  value: {
    fontFamily: "Lato",
    fontSize: 10,
    width: 100,
  },
});

export default function InfoElement(props) {
  const {id, label, value} = props;
  return (
    <View style={styles.root} key={id.toString()}>
      <Text style={styles.label}>{label}</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
  );
}
