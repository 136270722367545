// React
import React from "react";

// Components
import BodyContainer from "../../components/BodyContainer";
import BusinessSettings from "./BusinessSettings";
import AccountSettings from "./AccountSettings";
import Typography from "@material-ui/core/Typography";
import SettingsTabs from "./SettingsTabs";
import {navigate} from "gatsby";
import * as routes from "../../constants/routes";
import queryString from "query-string";
import {useLocation} from "@reach/router";

export default function BusinessSettingsPage(props) {
  const {match} = props;
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);

  const handleToggleTab = (event, value) =>
    navigate(queryString.stringifyUrl({
      url: routes.clientSettingsTab.replace(":tab", value),
      query: parsedQueries,
    }));

  return (
    <BodyContainer topGutter bottomGutter>
      <Typography variant="h5" component="h1">Settings</Typography>
      <SettingsTabs
        activeTab={match.tab}
        onToggleTab={handleToggleTab}
      />
      {match.tab === "account" ? (
        <AccountSettings/>
      ) : (
        <BusinessSettings/>
      )}
    </BodyContainer>
  );
}
