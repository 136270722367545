// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import {Tab, Tabs} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const tabs = [
  {
    label: "Business",
    value: "business",
  },
  {
    label: "Account",
    value: "account",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  tabs: {
    // width: "100%",
    // display: "flex",
    // justifyContent: "space-between",
    // padding: "0px 30px 0px 30px",
    // borderBottom: `1px solid ${theme.palette.borders}`,
  },
  tabRoot: {
    width: 120,
    minWidth: 120,
  },
  wrapper: {
    // height: 45,
    width: 100,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function SettingsTabs(props) {
  const {activeTab, onToggleTab} = props;
  const classes = useStyles(props);

  return (
    <Tabs
      value={activeTab}
      onChange={onToggleTab}
      classes={{
        root: classes.root,
        indicator: classes.indicator,
        tabs: classes.tabs,
      }}
    >
      {tabs.map(({label, value, disabled}) =>
        <Tab
          key={value}
          value={value}
          label={label}
          color="primary"
          classes={{
            root: classes.tabRoot,
            wrapper: classes.wrapper,
          }}
          className={classes.tab}
          disabled={disabled}
        />
      )}
    </Tabs>
  );
}

SettingsTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onToggleTab: PropTypes.func.isRequired,
};
