// React
import React from "react";

// Context
import {BusinessContext} from "../../components/Session";
import {FirebaseContext} from "../../components/Firebase";

// Components
import {
  CurrencyPanel,
  BusinessNamePanel,
  PaymentMethodsPanel, 
  BillingDetailsPanel, 
  CountryPanel
} from "../../components/SettingsPanels";

export default function BusinessSettingsPage(props) {
  // const [showPublishedDialog, setShowPublishedDialog] = React.useState(false);
  const {selectedBusiness} = React.useContext(BusinessContext);
  const [expanded, setExpanded] = React.useState({
    name: false,
    currency: false,
    billingDetails: false,
    paymentMethods: false,
  });
  const firebase = React.useContext(FirebaseContext);

  const handlePanelChange = (panel) => (event, value) => {
    setExpanded({
      ...expanded,
      [panel]: value,
    });
  };

  return (
    <div>
      <BusinessNamePanel
        firebaseRef={firebase.business(selectedBusiness.uid)}
        value={selectedBusiness.name}
        isExpanded={Boolean(expanded.name)}
        handlePanelChange={handlePanelChange("name")}
      />
      <CountryPanel
        firebaseRef={firebase.business(selectedBusiness.uid)}
        value={selectedBusiness.country}
        isExpanded={Boolean(expanded.country)}
        handlePanelChange={handlePanelChange("country")}
        canMakeEdits={false}
      />
      <CurrencyPanel
        firebaseRef={firebase.business(selectedBusiness.uid)}
        value={selectedBusiness.currency}
        isExpanded={Boolean(expanded.currency)}
        handlePanelChange={handlePanelChange("currency")}
        canMakeEdits={false} // So user cannot edit this after completing business
      />
      <BillingDetailsPanel
        business={selectedBusiness}
        handlePanelChange={handlePanelChange("billingDetails")}
        isExpanded={expanded.billingDetails}
      />
      <PaymentMethodsPanel
        isExpanded={Boolean(expanded.paymentMethods)}
        handlePanelChange={handlePanelChange("paymentMethods")}
      />
    </div>
  );
}
