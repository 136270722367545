// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    marginBottom: 30,
  },
  text: {
    display: "inline-block",
    marginTop: 10,
    marginRight: 20,
    float: "left",
  },
  button: {
    float: "right",
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
  },
  addBtnIcon: {
    opacity: 0.8,
  },
}));

export default function HeaderSection(props) {
  const {handleAddItem} = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        Create items with titles, descriptions and images
      </Typography>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={handleAddItem}
        startIcon={<Add className={classes.addBtnIcon}/>}
      >
        Item
      </Button>
    </div>
  );
}

HeaderSection.propTypes = {
  handleAddItem: PropTypes.func.isRequired,
};
