import {paymentsApi} from "../../constants/apiRoutes";
import axios from "axios";
import * as routes from "../../constants/routes";
import queryString from "query-string";

export function createCheckoutSession(business) {
  return new Promise((resolve, reject) => {
    const payload = {
      customer: business.stripeCustomerId,
      success_url: queryString.stringifyUrl({
        url: `${process.env.GATSBY_DOMAIN}${routes.clientSettingsTab.replace(":tab", "business")}`,
        query: {business: business.uid},
      }),
      cancel_url: queryString.stringifyUrl({
        url: `${process.env.GATSBY_DOMAIN}${routes.clientSettingsTab.replace(":tab", "business")}`,
        query: {business: business.uid},
      }),
    };
    return axios
      .post(`${paymentsApi}/sessions`, payload)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export function retrieveCheckoutSession(sessionId) {
  return new Promise((resolve, reject) => {
    return axios
      .get(`${paymentsApi}/sessions/${sessionId}`)
      .then((result) => resolve(result.data))
      .catch((error) => reject(error));
  });
}