//React
import React from "react";
import PropTypes from "prop-types";

// Context
import {AuthUserContext} from "../Session"; // BusinessContext

// Constants
import {manager, freelancer} from "../../constants/roles";

// Components
import SettingsPanel from "../SettingsPanel";

// constants
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Utils
import {getCurrencySymbol} from "../../utils/formatting";

function getLabel(selectedCurrency) {
  const symbol = getCurrencySymbol(selectedCurrency, true);
  return `${selectedCurrency}${symbol ? ` - ${symbol}` : ""}`;
}

export default function CurrencyPanel(props) {
  const authUser = React.useContext(AuthUserContext);
  const {
    handlePanelChange,
    isExpanded,
    firebaseRef,
    value,
    canMakeEdits,
    draftMode,
  } = props;
  const [selectedCurrency, setSelectedCurrency] = React.useState(value);
  const [lastSavedCurrency, setLastSavedCurrency] = React.useState(value);
  const [isSaving, setIsSaving] = React.useState(false);

  const supportedCurrencies = authUser.role === "manager"
    ? manager.supportedCurrencies
    : freelancer.supportedCurrencies;
  const currencies = supportedCurrencies
    .map(c => ({
      value: c,
      label: getLabel(c, authUser.country),
    }));

  const handleChange = (prop, value) => setSelectedCurrency(value);

  const handleCancel = () => {
    setSelectedCurrency(lastSavedCurrency);
    handlePanelChange && handlePanelChange();
  };

  const handleSave = () => {
    setIsSaving(true);
    const updates = {currency: selectedCurrency};
    if (authUser.role === "freelancer") {
      updates.hourlyRate = null;
    }
    firebaseRef
      .update(updates)
      .then(() => {
        if (!draftMode) {
          setIsSaving(false);
          setLastSavedCurrency(selectedCurrency);
          handlePanelChange && handlePanelChange();
        }
      });
  };

  const selectedCurrencyObj = currencies
    .filter(({value}) => value === selectedCurrency)[0];

  return (
    <SettingsPanel
      id="currency-panel"
      expanded={isExpanded}
      handlePanelChange={handlePanelChange}
      label="Currency"
      expandedText="Select a default currency"
      collapsedText={selectedCurrencyObj ? selectedCurrencyObj.label : null}
      onCancel={canMakeEdits ? handleCancel : null}
      onSave={canMakeEdits ? handleSave : null}
      isSaving={isSaving}
      unsavedChanges={
        selectedCurrency && selectedCurrency !== lastSavedCurrency
      }
      draftMode={draftMode}
      //error={error}
    >
      <Autocomplete
        id="currency-select"
        options={currencies}
        autoHighlight
        getOptionLabel={(option) => option.label || ""}
        getOptionSelected={(option) => option.value === selectedCurrency}
        defaultValue={null}
        value={selectedCurrencyObj || null}
        onChange={(event, option) =>
          handleChange("selectedCurrency", option ? option.value : null)
        }
        renderOption={(option) => (
          <React.Fragment>{option.label}</React.Fragment>
        )}
        disabled={!canMakeEdits}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Currency"
            variant="outlined"
            fullWidth
            helperText={
              canMakeEdits
                ? null
                : `Cannot be edited`
            }
          />
        )}
      />

    </SettingsPanel>
  );
}

CurrencyPanel.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  handlePanelChange: PropTypes.func,
  selectedBusiness: PropTypes.object,
  canMakeEdits: PropTypes.bool,
  draftMode: PropTypes.bool,
};
