//React
import React from "react";
import PropTypes from "prop-types";

// Components
import SettingsPanel from "../SettingsPanel";

// Context
import {FirebaseContext} from "../Firebase";
import {AuthUserContext} from "../Session";

// services
import {fetchLanguages} from "../../services/languages";

// Utils
import {isEqual} from "../../utils/validators";

// Material UI
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function LanguagesPanel(props) {
  const authUser = React.useContext(AuthUserContext);
  const [languages, setLanguages] = React.useState([]);
  const [selectedLanguages, setSelectedLanguages] = React.useState(authUser.languages || []);
  const [lastSavedLanguages, setLastSavedLanguages] = React.useState(authUser.languages || []);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(true);
  const firebase = React.useContext(FirebaseContext);
  const {isExpanded, handlePanelChange, draftMode} = props;

  React.useEffect(() => {
    fetchLanguages()
      .then((languages) => {
        setIsFetching(false);
        const formatted = languages
          .map(({code, name}) => ({
            label: name,
            value: code,
          }));
        setLanguages(formatted);
      });
  }, []);

  const handleChange = options => setSelectedLanguages(options.map(({value}) => value));

  const handleCancel = () => {
    setSelectedLanguages(lastSavedLanguages);
    handlePanelChange && handlePanelChange();
  };

  const handleSave = () => {
    setIsSaving(true);
    firebase
      .user(authUser.uid)
      .update({
        languages: selectedLanguages,
      })
      .then(() => {
        if (!draftMode) {
          setLastSavedLanguages(selectedLanguages);
          setIsSaving(false);
        }
        handlePanelChange && handlePanelChange();
      });
  };


  return (
    <SettingsPanel
      id="languages-panel"
      expanded={isExpanded}
      isLoading={isFetching}
      handlePanelChange={handlePanelChange}
      label="Languages"
      expandedText="Add the languages you want to support"
      collapsedText={
        selectedLanguages.length > 0
          ? languages
          .filter(({value}) => selectedLanguages.includes(value))
          .map(({label}) => label)
          .join(", ")
          : ""
      }
      onCancel={handleCancel}
      onSave={handleSave}
      isSaving={isSaving}
      unsavedChanges={!isEqual(selectedLanguages, lastSavedLanguages)}
      draftMode={draftMode}
      //error={error}
    >
      <Autocomplete
        id="languages-select"
        multiple
        options={languages}
        autoHighlight
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label || ""}
        value={languages.filter(({value}) => selectedLanguages.includes(value))}
        onChange={(event, option) => handleChange(option)}
        renderOption={(option) => (
          <React.Fragment>{option.label}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Languages"
            variant="outlined"
            fullWidth
            helperText="You should be able to speak clearly and write grammatically correct in the languages you add."
          />
        )}
      />
    </SettingsPanel>
  );
}

LanguagesPanel.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  handlePanelChange: PropTypes.func,
};
