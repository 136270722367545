import React from "react";
import {View, StyleSheet} from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";

const styles = StyleSheet.create({
  title: {
    marginTop: 10,
    fontSize: 12,
    fontFamily: "Lato",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 30,
    borderWidth: 1,
    borderColor: "#EBEBEB",
    borderRadius: 5,
  },
});

export default function InvoiceTable(props) {
  const {rows} = props;
  return (
    <React.Fragment>
      <View style={styles.tableContainer}>
        <InvoiceTableHeader/>
        {rows.map((row, idx) => (
          <InvoiceTableRow
            row={row}
            isLastRow={rows.length === idx + 1}
            key={idx}
          />
        ))}
      </View>
    </React.Fragment>
  );
}
