// React
import React from "react";
import PropTypes from "prop-types";
import {useLocation} from "@reach/router";
import {navigate} from "gatsby";

// // Other libs
import SettingsPanel from "../../SettingsPanel";
import {BusinessContext} from "../../Session";
import {FirebaseContext} from "../../Firebase";
import PaymentMethodsList from "./PaymentMethodList";
import PaymentMethodToolbar from "./PaymentMethodToolbar";
import SimpleDialog from "../../SimpleDialog";

// Services
import {retrievePaymentMethods} from "../../../services/payments/paymentMethods";

// Material UI
import {Divider} from "@material-ui/core";

// Other libs
import queryString from "query-string";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";
import {retrieveCustomer, updateDefaultPaymentMethod} from "../../../services/payments/customers";
import {retrieveCheckoutSession} from "../../../services/payments/sessions";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

function useFetchPaymentMethods(business, checkoutSessionId) {
  const [value, setValue] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const firebase = React.useContext(FirebaseContext);

  React.useEffect(() => {
    if (business.stripeCustomerId) {
      firebase.auth.currentUser
        .getIdToken(true)
        .then((idToken) => retrievePaymentMethods(business.uid, idToken))
        .then(result => {
          setValue(result);
          setLoading(false);
        })
    } else {
      setValue([]);
      setLoading(false);
    }
  }, [firebase.auth.currentUser, business.stripeCustomerId, business.uid]);

  return [value, loading];
}

function useStripeCustomer(stripeCustomerId) {
  const [value, setValue] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (stripeCustomerId) {
      retrieveCustomer(stripeCustomerId)
        .then(customer => {
          setValue(customer);
          setLoading(false);
        });
    } else {
      setValue(null);
      setLoading(false);
    }
  }, [stripeCustomerId]);

  return [value, loading];
}

function useFetchCheckoutSession(sessionId) {
  const [value, setValue] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (sessionId) {
      retrieveCheckoutSession(sessionId)
        .then(session => {
          setValue(session);
          setLoading(false);
        });
    } else {
      setValue(null);
      setLoading(false);
    }
  }, [sessionId]);

  return [value, loading];

}

export default function PaymentMethodsPanel(props) {
  const {handlePanelChange, isExpanded} = props;

  // Get checkout session id from param
  const location = useLocation();
  const parsedQueries = queryString.parse(location.search);

  // Get paymentMethods and stripeCustomer
  const {selectedBusiness} = React.useContext(BusinessContext);
  const [stripeCustomer, loadingStripeCustomer] = useStripeCustomer(selectedBusiness.stripeCustomerId);
  const [session, loadingSession] = useFetchCheckoutSession(parsedQueries.session);
  const [paymentMethods, loadingPaymentMethods] = useFetchPaymentMethods(selectedBusiness, parsedQueries.session);

  const initDefaultPmId = (stripeCustomer && stripeCustomer.invoice_settings.default_payment_method) ? stripeCustomer.invoice_settings.default_payment_method.id : null;
  const [defaultPmId, setDefaultPmId] = React.useState(initDefaultPmId);
  const [updatingDefaultPm, setUpdatingDefaultPm] = React.useState(false);

  React.useEffect(() => setDefaultPmId(initDefaultPmId), [initDefaultPmId]);

  const handleCloseDialog = () => navigate(`${location.pathname}?business=${parsedQueries.business}`);

  const handleChangeDefaultPm = pmId => {
    if (stripeCustomer) {
      setUpdatingDefaultPm(true);
      updateDefaultPaymentMethod(stripeCustomer.id, pmId)
        .then((res) => {
          if (res) {
            setDefaultPmId(res.invoice_settings.default_payment_method);
          }
          setUpdatingDefaultPm(false)
        })
    }
  };

  const handleConfirmNewDefault = () => {
    if (session) {
      handleChangeDefaultPm(session.setup_intent.payment_method)
    }
    return handleCloseDialog();
  };


  const hasDefaultPm = Boolean(defaultPmId);
  return (
    <div>
      {(parsedQueries.session) && (
        <SimpleDialog
          id="pm-added-dialog"
          isLoading={loadingSession}
          toggleDialog={handleCloseDialog}
          open={true}
          title="Payment method added"
          description={hasDefaultPm
            ? "Should this method be used as the default for all future charges?"
            : "By default it will be used for all future charges"
          }
          secondaryActionText={hasDefaultPm ? "No, keep current default" : null}
          handleSecondaryAction={hasDefaultPm ? handleCloseDialog : null}
          primaryActionText={hasDefaultPm ? "Yes, use as default" : "Ok"}
          handlePrimaryAction={handleConfirmNewDefault}
        />
      )}
      <SettingsPanel
        id="payment-methods-panel"
        expanded={isExpanded}
        handlePanelChange={handlePanelChange}
        label="Pay methods"
        expandedText="Manage your payment methods"
        isLoading={loadingPaymentMethods || loadingStripeCustomer}
        collapsedText={paymentMethods.length > 0 ? `${paymentMethods.length} payment method${paymentMethods.length === 1 ? "" : "s"}` : null}
        onCancel={null}
        onSave={null}
        isSaving={false}
        unsavedChanges={false}
        //error={error}
      >
        <Elements stripe={stripePromise}>
          <PaymentMethodToolbar/>
          <Divider/>
          <PaymentMethodsList
            paymentMethods={paymentMethods}
            defaultPmId={defaultPmId}
            updatingDefaultPm={updatingDefaultPm}
            handleChangeDefaultPm={handleChangeDefaultPm}
            // stripeCustomer={stripeCustomer}
          />
        </Elements>
      </SettingsPanel>
    </div>
  );
};

PaymentMethodsPanel.propTypes = {
  handlePanelChange: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  // proposal: PropTypes.object.isRequired,
};

