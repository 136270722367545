import {paymentsApi} from "../../constants/apiRoutes";

const defaultOptions = {
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
  referrer: "no-referrer",
};

export function createPaymentIntent({amount, metadata, currency, customer, paymentMethodId, stripeAccountId, applicationFee}) {
  return new Promise((resolve, reject) => {
    // Stripe charges in cents
    fetch(`${paymentsApi}/intents`, {
      ...defaultOptions,
      method: "POST",
      body: JSON.stringify({
        amount,
        applicationFee,
        currency,
        customer,
        paymentMethodId,
        stripeAccountId,
        metadata,
      }),
    })
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export function getEstTransactionFee(paymentMethod, chargeAmount) {
  const {type, card} = paymentMethod;
  const transactionFee = {
    percentage: 0.03,
    amount: 0,
  };
  if (type === "card") {
    const {country} = card;
    const fixedCardFee = 25; // Equals €0.25
    if (country === "US") {
      transactionFee.percentage = 0.029;
    } else {
      transactionFee.percentage = 0.014;
    }
    transactionFee.amount = (chargeAmount * transactionFee.percentage) + fixedCardFee;
  } else if (type === "sepa_debit") {
    transactionFee.percentage = 0.01;
    transactionFee.amount = (chargeAmount * transactionFee.percentage);
  }
  return {
    ...transactionFee,
    percentage: (transactionFee.amount / chargeAmount).toFixed(2),
  };
}