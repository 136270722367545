import React from "react";
import PropTypes from "prop-types";
import {PDFDownloadLink} from "@react-pdf/renderer";

// Components
import InvoicePdf from "./InvoicePdf";

// Utils
import {makeStyles} from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export default function InvoiceDownloadButton(props) {
  const classes = useStyles();
  const {
    issuedFromDetails,
    issuedToDetails,
    bill,
    sessions,
    feeType,
    buttonText,
  } = props;

  console.log("sessions", sessions)

  return (
    <PDFDownloadLink
      document={
        <InvoicePdf
          issuedFromDetails={issuedFromDetails}
          issuedToDetails={issuedToDetails}
          bill={bill}
          sessions={sessions}
          feeType={feeType}
          {...props}
        />
      }
      fileName={`${
        (feeType === "platformFees" || feeType === "fees") ? "maxer" : "freelancer"
      }-invoice-${bill.uid}.pdf`}
      className={classes.root}
    >
      {({blob, url, loading, error}) => (
        <Button
          color="primary"
          variant="outlined"
          disabled={loading || !issuedFromDetails || !issuedToDetails}
          startIcon={<GetAppIcon/>}
        >
          {buttonText || "Download invoice"}
        </Button>
      )}
    </PDFDownloadLink>
  );
}

InvoiceDownloadButton.propTypes = {
  feeType: PropTypes.oneOf(["platformFees", "freelancerFees"]).isRequired,
};
