import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import DeleteForever from "@material-ui/icons/DeleteForever";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  iconButton: {
    float: "right",
    position: "relative",
    top: 10,
    right: 10,
    background: "rgba(0, 0, 0, 0.2)",
    zIndex: 1,
  },
  iconMoreVert: {
    color: "rgba(255.0, 255.0, 255.0, 0.8)",
  },
  iconLeft: {
    transform: "rotate(180deg)",
  },
  listItemContainer: {
    position: "relative",
    top: -48,
  },
  deleteMenu: {
    marginTop: 10,
  },
}));

export default function SettingsWrapper(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    item,
    canMoveBackward,
    canMoveForward,
    changeOrder,
    deleteItem,
    children,
  } = props;
  const classes = useStyles();

  const handleMenuIconClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleChangeOrder = (item, direction) => {
    handleMenuClose();
    changeOrder(item, direction);
  };

  const handleDelete = (item) => {
    handleMenuClose();
    deleteItem(item);
  };

  return (
    <div className={classes.root}>
      <IconButton className={classes.iconButton} onClick={handleMenuIconClick}>
        <MoreVert className={classes.iconMoreVert}/>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {(canMoveBackward || canMoveForward) && (
          <MenuItem disabled={true}>
            <Typography variant="caption">Change order</Typography>
          </MenuItem>
        )}
        {(canMoveBackward || canMoveForward) && <Divider/>}
        {canMoveBackward && (
          <MenuItem onClick={() => handleChangeOrder(item, "first")}>
            <ListItemIcon className={classes.icon}>
              <ArrowBack/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              inset
              primary="Move to first position"
            />
          </MenuItem>
        )}
        {canMoveBackward && (
          <MenuItem onClick={() => handleChangeOrder(item, "backward")}>
            <ListItemIcon className={classes.icon}>
              <KeyboardArrowLeft/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              inset
              primary="Move backward"
            />
          </MenuItem>
        )}
        {canMoveForward && (
          <MenuItem onClick={() => handleChangeOrder(item, "forward")}>
            <ListItemIcon className={classes.icon}>
              <KeyboardArrowRight/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              inset
              primary="Move forward"
            />
          </MenuItem>
        )}
        {canMoveForward && (
          <MenuItem onClick={() => handleChangeOrder(item, "last")}>
            <ListItemIcon className={classes.icon}>
              <ArrowForward/>
            </ListItemIcon>
            <ListItemText
              classes={{primary: classes.listItemText}}
              inset
              primary="Move to last position"
            />
          </MenuItem>
        )}
        {(canMoveBackward || canMoveForward) && <Divider/>}
        <MenuItem
          onClick={() => handleDelete(item)}
          className={classes.deleteMenu}
        >
          <ListItemIcon className={classes.icon}>
            <DeleteForever/>
          </ListItemIcon>
          <ListItemText
            classes={{primary: classes.listItemText}}
            inset
            primary="Delete"
          />
        </MenuItem>
      </Menu>
      <div className={classes.listItemContainer}>{children}</div>
    </div>
  );
}

SettingsWrapper.propTypes = {
  item: PropTypes.object.isRequired,
  canMoveBackward: PropTypes.bool.isRequired,
  canMoveForward: PropTypes.bool.isRequired,
  changeOrder: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};
