import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BrokenImage from "@material-ui/icons/BrokenImage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    width: "100%",
    cursor: "pointer",
  },
  image: {
    objectFit: "cover",
    minWidth: "100%",
    maxWidth: "100%",
    width: "auto",
    borderRadius: "0.3rem",
  },
  brokenImageIcon: {
    fontSize: 100,
    display: "block",
    color: "rgba(0, 0, 0, 0.1)",
    margin: "0 auto",
  },
  headline: {
    marginTop: 10,
    marginLeft: 10,
  },
}));

const getFeaturedImage = (item) => {
  if (item.assetUrl) {
    return item.assetUrl;
  } else if (item.images && item.images[0] && item.images[0].assetUrl) {
    return item.images[0].assetUrl;
  } else {
    return null;
  }
};

export default function ListItem(props) {
  const {item, handleClick, height} = props;
  const classes = useStyles();
  const featuredImage = getFeaturedImage(item);

  return (
    <Box
      key={item.headline}
      className={classes.root}
      onClick={handleClick}
      role="listitem"
      // // TODO: Check how to handle more gracefully
      // onKeyDown={handleClick}
    >
      {featuredImage ? (
        <img
          src={featuredImage}
          title={item.headline}
          alt={item.headline}
          className={classes.image}
          style={{
            height: height,
            minHeight: height,
          }}
        />
      ) : (
        <div
          className={classes.image}
          style={{
            height: height,
            minHeight: height,
            background: "#fff", //theme.palette.background.light,
            display: "flex",
            alignItems: "center",
          }}
        >
          <BrokenImage className={classes.brokenImageIcon}/>
        </div>
      )}
      {item.headline && (
        <Typography
          component="h5"
          variant="subtitle1"
          className={classes.headline}
        >
          {item.headline}
        </Typography>
      )}
    </Box>
  );
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
};
