// React
import React from "react";
import PropTypes from "prop-types";

// Components ui
import FilterDropdown from "./FilterDropdown";

// Material ui
import {Box, Typography, InputBase} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Search} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: ({bottomGutterSpacing}) => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(bottomGutterSpacing || 2),
    marginTop: theme.spacing(2),
    maxWidth: 400,
  }),
  filterContainer: {
    display: "flex",
    background: theme.palette.borders,
  },
  filterLabel: {
    marginTop: 'auto',
    marginBottom: 'auto',
    opacity: 0.65,
    marginLeft: theme.spacing(1),
  },
  input: {
    marginLeft: 8,
    flex: 1,
    fontSize: 12,
  },
  searchIcon: {
    paddingLeft: theme.spacing(1),
    opacity: 0.4,
    margin: "auto"
  },
  searchContainer: {
    height: theme.spacing(4.5),
    display: "flex",
    border: `1px solid ${theme.palette.borders}`,
    borderRadius: "0rem 0.2rem 0.2rem 0rem",
  }
}));

export default function FilterBar(props) {

  const {
    searchQueryPlaceholder,
    onSearch,
    filterOneLabel,
    filterOneOptions,
    filterOneValue,
    onFilterOneChange,
  } = props;

  const classes = useStyles(props);

  // const hasTwoFilters = Boolean(rightLabelTwo && rightItemsTwo);

  return (
    <Box className={classes.root}>
      <Box className={classes.filterContainer}>
        <Typography variant="body2" className={classes.filterLabel}>
          {`${filterOneLabel}: `}
        </Typography>
        <FilterDropdown
          options={filterOneOptions}
          value={filterOneValue}
          onChange={onFilterOneChange}
        />
      </Box>
      {/*<Divider className={classes.divider}/>*/}
      <Box className={classes.searchContainer}>
        <Search className={classes.searchIcon}/>
        <InputBase
          className={classes.input}
          placeholder={searchQueryPlaceholder}
          onChange={onSearch()}
          disabled
        />
      </Box>
      {/*{hasTwoFilters && (*/}
      {/*  <Divider className={classes.divider}/>*/}
      {/*)}*/}
      {/*{hasTwoFilters && (*/}
      {/*  <DropdownChip*/}
      {/*    menuId="menuTwo"*/}
      {/*    labelText={rightLabelTwo}*/}
      {/*    anchorEl={anchorEl}*/}
      {/*    isInactive={Boolean(activeMenu !== "menuTwo")}*/}
      {/*    selected={rightSelectedTwo}*/}
      {/*    selectedColor={getSelectedColor(theme.palette, rightSelectedTwo)}*/}
      {/*    menuItems={rightItemsTwo}*/}
      {/*    onToggle={() => toggleMenu("menuTwo")}*/}
      {/*  />*/}
      {/*)}*/}
    </Box>
  );
}

FilterBar.propTypes = {
  searchQueryPlaceholder: PropTypes.string,
  onSearch: PropTypes.func,
  filter: PropTypes.string,
  filterOneOptions: PropTypes.array,
  filterOneValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onFilterOneChange: PropTypes.func,
};
