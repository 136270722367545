// React
import React from "react";
import PropTypes from "prop-types";

// Components
import { BusinessContext } from "../../components/Session";
import {FirebaseContext, useFetchFirestore} from "../../components/Firebase";
import BodyContainer from "../../components/BodyContainer";
import BillActionCard from "./BillActionCard";
import LoadingIndicator from "../../components/LoadingIndicator";
import PageNotFound from "../../components/PageNotFound";
import BillSessionsTable from "../../components/BillSessionsTable";

// Utils
import {convertUnixTsToDate} from "../../utils/time";

// Stripe
import {loadStripe} from '@stripe/stripe-js/pure';
import {Elements} from "@stripe/react-stripe-js";
import { formatCentsToDisplayPrice } from "../../utils/formatting";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

export default function ClientBill(props) {

  const {match} = props;

  const firebase = React.useContext(FirebaseContext);
  const {selectedBusiness} = React.useContext(BusinessContext);

  const [bill, loadingBill] = useFetchFirestore(
    firebase.bill(match.billId), 
    { 
      listen: true, 
      transformResult: (r) => r ? ({
        ...r,
        hourlyRate: formatCentsToDisplayPrice(r.hourlyRate),
        monthlyBudget: formatCentsToDisplayPrice(r.monthlyBudget),
      }) : null
    }
  )

  const sessionsRef = bill ? (
    firebase.sessions()
      .where("businessId", "==", selectedBusiness.uid)
      .where("billId", "==", bill.uid)
      .where("excluded", "==", false)
  ) : null;
  const [sessions, loadingSessions] = useFetchFirestore(
    sessionsRef,
    {
      type: "collection", 
      listen: true, 
      stopLoading: !bill && !loadingBill,
      transformResult: r => r.map(s => ({
        ...s,
        startedDate: convertUnixTsToDate(s.startedAt.seconds),
        endedDate: convertUnixTsToDate(s.endedAt.seconds),
        amountExcl: formatCentsToDisplayPrice(s.amountExcl),
      }))
    }
  );

  const [freelancer, loadingFreelancer] = useFetchFirestore(
    bill ? firebase.user(bill.ownerId) : null,
    { stopLoading: !loadingBill && !bill }
  )
  const [team, loadingTeam] = useFetchFirestore(
    bill ? firebase.team(bill.teamId) : null, 
    { stopLoading: !loadingBill && !bill }
  )

  if (loadingBill || loadingSessions || loadingFreelancer || loadingTeam) {
    return <LoadingIndicator message="Loading bill..." inheritHeight topSpacing={10}/>;
  } else if (!bill || !freelancer || !team) {
    return <PageNotFound/>;
  }

  return (
    <BodyContainer topGutterSpacing={4} bottomGutter>
      <Elements stripe={stripePromise}>
        <BillActionCard
          bill={bill}
          sessions={sessions}
          freelancer={freelancer}
          team={team}
        />
      </Elements>
      <BillSessionsTable
        sessions={sessions}
        bill={bill}
      />
    </BodyContainer>
  );
}

ClientBill.propTypes = {
  match: PropTypes.object.isRequired,
};
