// React
import React from "react";
import PropTypes from "prop-types";
import {navigate} from "gatsby";

// Components
import ConfirmToolbar from "../../../components/ConfirmToolbar";

// Material UI
import {CardActions, Button, CircularProgress} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';

// Utils/constants
import {getCurrencyFormat} from "../../../utils/formatting";
import InvoiceDownloadButton from "../../../components/Invoice/InvoiceDownloadButton";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    float: "right"
  },
  disputeBillBtn: {
    marginRight: theme.spacing(1),
  }
}));

export default function CardActionsContainer(props) {
  const {loading, hasDefaultPaymentMethod, bill, sessions, billStatus, isPaying, handlePayBill, businessSettingsRoute, handleToggleDisputeDialog} = props;
  const [confirmPay, setConfirmPay] = React.useState(false);
  const classes = useStyles(props);

  const handleToggleConfirmPay = () => setConfirmPay(cp => !cp);

  const onPayBill = () => {
    handlePayBill();
    handleToggleConfirmPay();
  };

  const handleFixPaymentMethod = () => navigate(String(businessSettingsRoute));
  if (billStatus === "processing") {
    return (
      <CardActions className={classes.root}>
        <Button
          className={classes.actionBtn}
          variant="contained"
          color="primary"
          startIcon={<CircularProgress size={15}/>}
          disabled
        >
          Processing payment
        </Button>
      </CardActions>
    )
  }

  if (billStatus === "paid") {
    return (
      <CardActions className={classes.root}>
        <InvoiceDownloadButton
          issuedFromDetails={bill.freelancerBillingDetails}
          issuedToDetails={bill.clientBillingDetails}
          bill={bill}
          sessions={sessions}
          feeType="freelancerFees"
        />
      </CardActions>
    )
  }

  if (loading) {
    return <LinearProgress/>
  }

  if (!hasDefaultPaymentMethod) {
    return (
      <CardActions className={classes.root}>
        <Button
          className={classes.actionBtn}
          color="primary"
          variant="contained"
          onClick={handleFixPaymentMethod}
        >
          Add payment method
        </Button>
      </CardActions>
    )
  }

  const payBtnText = `Pay ${getCurrencyFormat(bill.totalAmount, bill.currency, {convertToDecimals: true})} bill`;
  return (confirmPay && !isPaying) ? (
    <CardActions className={classes.root}>
      <ConfirmToolbar
        onConfirm={onPayBill}
        confirmButtonText={payBtnText}
        onCancel={handleToggleConfirmPay}
        variant="dense"
        disableGutters
      />
    </CardActions>
  ) : (
    <CardActions className={classes.root}>
      {billStatus === "paymentFailed" ? (
        <Button
          className={classes.actionBtn}
          color="primary"
          onClick={handleFixPaymentMethod}
        >
          Fix payment method
        </Button>
      ) : (
        <Button
          className={classes.disputeBillBtn}
          onClick={handleToggleDisputeDialog}
        >
          Dispute bill
        </Button>
      )}
      <Button
        className={classes.actionBtn}
        variant="contained"
        color="primary"
        onClick={() => handleToggleConfirmPay(bill.uid)}
        startIcon={isPaying ? <CircularProgress size={15}/> : null}
        disabled={isPaying}
      >
        {payBtnText}
      </Button>
    </CardActions>
  );
}

CardActionsContainer.propTypes = {
  bill: PropTypes.object.isRequired,
  hasDefaultPaymentMethod: PropTypes.bool,
};


