// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StatusLabel from "../../StatusLabel";
import EmptyStateView from "../../EmptyStateView";

// Material UI
import {CreditCard, MoreVert} from "@material-ui/icons";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import LoadingIndicator from "../../LoadingIndicator";

const paymentMethodLogos = {
  card: {
    mastercard: "https://images.ctfassets.net/h6w739vsk2p5/1jMBmWgahf8rDfEF946RD1/15cdf4dae9dea83a877fb323361f2b4e/payment-method-card-logo_mastercard.svg",
    visa: "https://images.ctfassets.net/h6w739vsk2p5/1bAg9rZqFHQc3mtyfCOJJ6/8983b1ca0aa119f5019bb705b42a2c7f/payment-method-card-logo_visa.svg",
    unionpay: "https://images.ctfassets.net/h6w739vsk2p5/6RZeJsYUmpksyLu62Z63qi/2c2dbaf4db7b648c8113df05307dc6a1/payment-method-card-logo_unionpay.svg",
    diners: "https://images.ctfassets.net/h6w739vsk2p5/o5MO0mYJcL9ZEjLYW7EfZ/eb9714c3042f75b155d71fd1f4b3bdf1/payment-method-card-logo_diners.svg",
    discover: "https://images.ctfassets.net/h6w739vsk2p5/3NCs9LCLUsyBejISDvwbB2/b0b6b82e66be92d587dea87717ee38af/payment-method-card-logo_discover.svg",
    amex: "https://images.ctfassets.net/h6w739vsk2p5/182ElU7WaEeDXOf8y5JZQ1/43ce8aefdaef355e3ffa5a0f44a323b1/payment-method-card-logo_american-express.svg",
    jcb: "https://images.ctfassets.net/h6w739vsk2p5/7LAunhRunnYFEuHlXBqELh/8fca610123f6813bfb7a1060bf121a33/payment-method-card-logo_jcb.svg",
    unknown: "https://images.ctfassets.net/h6w739vsk2p5/4Loik1LOtKRvzQ7CLFU89u/b17b6c61fc1b3d410e58fd6bb1e20c38/payment-method-card-logo_unknown.svg",
  },
  sepa_debit: 'https://images.ctfassets.net/h6w739vsk2p5/6q9jJoby8bLA0rq8Zcwmx2/fff1fa78a0047ddcdbb177ea009f9cb0/sepa_debit.svg',
  unknown: "https://images.ctfassets.net/h6w739vsk2p5/4Loik1LOtKRvzQ7CLFU89u/b17b6c61fc1b3d410e58fd6bb1e20c38/payment-method-card-logo_unknown.svg",
};

function getSecondaryText(pm) {
  if (pm.type === "card") {
    const {last4, exp_month, exp_year} = pm.card;
    return `•••• •••• •••• ${last4} - Expires on ${exp_month}/${exp_year}`;
  } else if (pm.type === "sepa_debit") {
    const {bank_code, country, last4} = pm.sepa_debit;
    return `•••• •••• •••• ${last4} - ${bank_code} ${country}`;
  }
  return `Unknown method`;
}

function getPaymentMethodLogo(pm) {
  if (pm.type === "card") {
    return paymentMethodLogos.card[pm.card.brand || "unknown"];
  } else if (pm.type === "sepa_debit") {
    return paymentMethodLogos.sepa_debit;
  }
  return paymentMethodLogos.unknown;
}

export default function PaymentMethodsList(props) {
  const {paymentMethods, updatingDefaultPm, defaultPmId, handleChangeDefaultPm} = props;
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleNewDefault = pmId => {
    handleCloseMenu();
    handleChangeDefaultPm(pmId)
  };

  if (updatingDefaultPm) {
    return (
      <LoadingIndicator
        message="Updating default payment method..."
        inheritHeight
        topSpacing={3}
        bottomSpacing={3}
      />
    )
  }

  return (
    <div>
      {paymentMethods.length > 0 ? (
        <List>
          {paymentMethods
            .map(pm => {
              const logoSrc = getPaymentMethodLogo(pm);
              const secondaryText = getSecondaryText(pm);
              const isDefaultMethod = Boolean(pm.id === defaultPmId);
              return (
                <ListItem value={pm.id} key={pm.id} disableGutters>
                  <ListItemIcon>
                    <img src={logoSrc} alt={pm.id} height="40"/>
                  </ListItemIcon>
                  <ListItemText
                    secondary={secondaryText}
                    style={{margin: "auto"}}
                  />
                  <ListItemSecondaryAction>
                    {isDefaultMethod ? (
                      <StatusLabel
                        value="default"
                        shape="square"
                        color={theme.palette.statusColors[grey[500]]}
                        popoverText="This method will automatically be charged to pay incoming bills"
                        size="small"
                      />
                    ) : (
                      <div>
                        <IconButton onClick={handleOpenMenu}>
                          <MoreVert/>
                        </IconButton>
                        <Menu
                          id="payment-method-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                        >
                          <MenuItem onClick={() => handleNewDefault(pm.id)}>Set as default</MenuItem>
                        </Menu>
                      </div>

                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          }
        </List>
      ) : (
        <EmptyStateView
          text="No payment method added yet"
          icon={<CreditCard/>}
          topSpacing={5}
        />
      )}
    </div>
  );
}

PaymentMethodsList.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  // proposal: PropTypes.object.isRequired,
};

