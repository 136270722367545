//React
import React from "react";
import PropTypes from "prop-types";

// Context
import {FirebaseContext, useFetchFirestore} from "../Firebase";
import {AuthUserContext} from "../Session";

// Components
import SettingsPanel from "../SettingsPanel";

// Other libs
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {countryToFlag} from "../../utils/formatting";
import {isEqual} from "../../utils/validators";
import * as yup from "yup";
import * as SalesTax from "sales-tax";
import {makeStyles} from "@material-ui/core/styles";
import {fetchCountries} from "../../services/countries";

function getFormattedCountries(rawCountriesList) {
  return rawCountriesList
    .filter(({ name }) => Boolean(name))
    .map(({cca2, name}) => ({
      value: cca2,
      label: name.common,
    }));
}

function getBusinessDetailsSchema(country, taxNumberRequired) {
  return yup.object({
    businessName: yup
      .string()
      .test("len", "Must be between 2 and 40 characters", (val) =>
        Boolean(val.length >= 2 && val.length <= 40)
      )
      .required("Enter your business name registered with your government"),
    taxNumber: yup
      .string()
      .nullable()
      .test(
        "is-valid-tax-nr",
        "Enter a valid tax number",
        (val) =>
          new Promise((resolve, reject) => {
            // If no tax number is entered then skip test unless the freelancer requires a tax id (withdraws proposal otherwise)
            !val && !taxNumberRequired && resolve(true);
            // Else check whether the tax id marks the user as a business
            SalesTax.setTaxOriginCountry(country);
            SalesTax.getSalesTax(country, null, val).then((tax) =>
              resolve(tax.exchange === "business")
            );
          })
      ),
    addressLineOne: yup.string().required("Enter an address"),
    postcode: yup.string().required("Enter a postcode/zip code"),
    city: yup.string().required("Enter a town/city"),
    country: yup.string().required("Select a country"),
  });
}

const useStyles = makeStyles((theme) => ({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
}));

export default function BillingDetailsPanel(props) {
  const {handlePanelChange, isExpanded, draftMode, taxNumberRequired, business} = props;
  const authUser = React.useContext(AuthUserContext);
  const firebase = React.useContext(FirebaseContext);
  const [loadingCountries, setLoadingCountries] = React.useState(true);
  const [countries, setCountries] = React.useState([]);
  const businessId = business ? business.uid : null;
  
  let billingDetailsRef = firebase.billingDetails()
    .where("ownerId", "==", authUser.uid);
  if (businessId) {
    billingDetailsRef = firebase.billingDetails()
      .where("ownerId", "==", authUser.uid)
      .where("businessId", "==", businessId)
  } 
  const [billingDetails, loadingBillingDetails] = useFetchFirestore(
    billingDetailsRef,
    { type: 'collection', listen: true, transformResult: r => r[0]}
  )

  const [fields, setFields] = React.useState({});
  const [isSaving, setIsSaving] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState({});

  const {
    businessName,
    taxNumber,
    addressLineOne,
    addressLineTwo,
    postcode,
    city,
    country,
  } = fields;
  const classes = useStyles();

  const initCountry = business ? business.country : authUser.country;
  React.useEffect(() => {
    console.log("kkk effect")
    if (billingDetails) {
      setFields({
        businessName: billingDetails.businessName,
        taxNumber: billingDetails.taxNumber,
        addressLineOne: billingDetails.addressLineOne,
        addressLineTwo: billingDetails.addressLineTwo,
        postcode: billingDetails.postcode,
        city: billingDetails.city,
        country: billingDetails.country,
      })
    } else if (!loadingBillingDetails) {
      setFields({
        businessName: "",
        taxNumber: "",
        addressLineOne: "",
        addressLineTwo: "",
        postcode: "",
        city: "",
        country: initCountry,
      })
    }
  }, [billingDetails, loadingBillingDetails, initCountry])

  React.useEffect(() => {
    fetchCountries()
      .then((res) => {
        setCountries(getFormattedCountries(res));
        setLoadingCountries(false);
      });
  }, []);

  const handleChange = (prop, value) => {
    setFields({
      ...fields,
      [prop]: value,
    });
  };

  const addBillingDetails = () => {
    let data = {
      createdAt: firebase.createTimestamp(),
      lastUpdatedAt: firebase.createTimestamp(),
      ownerId: authUser.uid,
      fullName: `${authUser.firstName} ${authUser.lastName}`,
      ...fields,
    };
    if (businessId) {
      data = {...data, businessId: businessId};
    }
    firebase
      .billingDetails()
      .add(data)
      .then(() => {
        !draftMode && setIsSaving(false);
        handlePanelChange && handlePanelChange();
      })
  };

  const updateBillingDetails = (ref) => {
    firebase.billingDetail(billingDetails.uid)
      .update({
        lastUpdatedAt: firebase.createTimestamp(),
        ...fields,
      })
      .then(() => {
        handlePanelChange();
        !draftMode && setIsSaving(false);
      })
  };

  const handleSave = () => {
    setIsSaving(true);
    handleValidateFields()
      .then((result) => {
        if (result.isValid) {
          (billingDetails && billingDetails.uid)
            ? updateBillingDetails()
            : addBillingDetails();
        } else {
          setIsSaving(false);
          setValidationErrors(result.validationErrors);
        }
      });
  };

  const handleCancel = () => {
    setFields(billingDetails);
    handlePanelChange && handlePanelChange();
  };

  const handleValidateFields = () => {
    return new Promise((resolve, reject) => {
      setValidationErrors({});
      getBusinessDetailsSchema(fields.country, taxNumberRequired)
        .validate(fields, {abortEarly: false})
        .then(() => resolve({isValid: true}))
        .catch((errors) => {
          const validationErrors = {};
          for (let i = 0; i < errors.inner.length; i++) {
            const error = errors.inner[i];
            validationErrors[error.path] = error.message;
          }
          resolve({isValid: false, validationErrors});
        });
    });
  };

  const selectedCountryObj = countries
    .filter((c) => c.value === country)[0];

  console.log("billingDetails jjj", billingDetails);
  const lastSavedFields = {
    businessName: billingDetails ? billingDetails.businessName : "",
    taxNumber: billingDetails ? billingDetails.taxNumber : "",
    addressLineOne: billingDetails ? billingDetails.addressLineOne : "",
    addressLineTwo: billingDetails ? billingDetails.addressLineTwo : "",
    postcode: billingDetails ? billingDetails.postcode : "",
    city: billingDetails ? billingDetails.city : "",
    country: initCountry,
  }
  const collapsedText = Boolean(billingDetails)
    ? `${billingDetails.businessName}${billingDetails.taxNumber ? ` (${billingDetails.taxNumber})` : ''}, ${billingDetails.addressLineOne}, ${billingDetails.postcode} ${billingDetails.city}...`
    : "";
  return (
    <SettingsPanel
      id="billing-details-panel"
      expanded={isExpanded}
      handlePanelChange={handlePanelChange}
      label="Billing details"
      expandedText="Enter billing details for your business"
      collapsedText={collapsedText.length > 60 ? `${collapsedText.substring(0, 60)}...` : collapsedText}
      onCancel={handleCancel}
      onSave={handleSave}
      isLoading={loadingCountries || loadingBillingDetails}
      isSaving={isSaving}
      unsavedChanges={!isEqual(fields, lastSavedFields)}
      draftMode={draftMode}
    >
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} md={6}>
          <TextField
            id="business-name"
            className={classNames(classes.textField, classes.dense)}
            label="Legal business name"
            fullWidth={true}
            value={businessName || ""}
            onChange={(e) => handleChange("businessName", e.target.value)}
            variant="outlined"
            error={Boolean(validationErrors.businessName)}
            helperText={
              validationErrors.businessName ||
              "The business name registered with your government"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="taxNumber"
            className={classNames(classes.textField, classes.dense)}
            label="Tax number"
            fullWidth={true}
            value={taxNumber || ""}
            onChange={(e) => handleChange("taxNumber", e.target.value)}
            variant="outlined"
            error={Boolean(validationErrors.taxNumber)}
            helperText={
              validationErrors.taxNumber ||
              "Assigned by your government (e.g. EIN, VAT nr.)"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="addressLineOne"
            className={classNames(classes.textField, classes.dense)}
            label="Address line 1"
            fullWidth={true}
            value={addressLineOne || ""}
            onChange={(e) => handleChange("addressLineOne", e.target.value)}
            variant="outlined"
            error={Boolean(validationErrors.addressLineOne)}
            helperText={
              validationErrors.addressLineOne ||
              "Street and number, P.O. box, c/o"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="addressLineTwo"
            className={classNames(classes.textField, classes.dense)}
            label="Address line 2 (optional)"
            fullWidth={true}
            value={addressLineTwo || ""}
            onChange={(e) => handleChange("addressLineTwo", e.target.value)}
            variant="outlined"
            helperText="Apartment, building, floor etc."
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="postcode"
            className={classNames(classes.textField, classes.dense)}
            label="Postcode"
            fullWidth={true}
            value={postcode || ""}
            onChange={(e) => handleChange("postcode", e.target.value)}
            variant="outlined"
            error={Boolean(validationErrors.postcode)}
            helperText={validationErrors.postcode}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            id="city"
            className={classNames(classes.textField, classes.dense)}
            label="Town/City"
            fullWidth={true}
            value={city || ""}
            onChange={(e) => handleChange("city", e.target.value)}
            variant="outlined"
            error={Boolean(validationErrors.city)}
            helperText={validationErrors.city}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            id="country-select"
            options={countries}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.label || ""}
            getOptionSelected={(option, value) => option.value === value.value}
            defaultValue={null}
            value={selectedCountryObj || null}
            onChange={(event, option) =>
              handleChange("country", option ? option.value : null)
            }
            renderOption={(option) => (
              <React.Fragment>
                <span>{countryToFlag(option.value)}</span>
                {option.label} ({option.value})
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </SettingsPanel>
  );
}

BillingDetailsPanel.propTypes = {
  handlePanelChange: PropTypes.func,
  isExpanded: PropTypes.bool.isRequired,
  draftMode: PropTypes.bool,
};
